import React from "react";
import {DateTimePicker} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";
import {Controller, ControllerProps} from "react-hook-form";
import {UseControllerProps} from "react-hook-form/dist/types/controller";

export const DateTimePickerInput: React.FC<UseControllerProps> = (props) => {
    return (
        <Controller
            {...props}
            render={({ field: { ref, onBlur, name, ...field }, fieldState }) => <DateTimePicker
                {...field}
                inputRef={ref}

                ampm={false}
                inputFormat="DD/MM/YYYY HH:mm"
                // onChange={(date: any, keyboardInputValue) => {
                //     const timestamp = keyboardInputValue ? dayjs(keyboardInputValue.trim(), "DD/MM/YYYY HH:mm", true) : undefined;
                //
                //     let value: any = undefined;
                //
                //     if (timestamp?.isValid()) {
                //         value = timestamp.toISOString();
                //     }
                //
                //     field.onChange({target: {value}})
                // }}
                label="Date"
                renderInput={(inputProps) => (
                    <TextField
                        {...inputProps}
                        InputLabelProps={{shrink: true}}
                        onBlur={onBlur}
                        name={name}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                    />
                )}
            />}
        />
    );
};
