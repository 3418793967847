import React from "react";

export interface DecimaleGraadProps {
    decimalDegree: {
        degrees: number;
        minutes: number;
        seconds: number;
    };

    showSeconds?: boolean;
}

export const DecimalDegree: React.FC<DecimaleGraadProps> = (props) => {
    const {showSeconds = true} = props;

    const {degrees, minutes, seconds} = props.decimalDegree;

    const secondenTekst = ` ${seconds}"`;

    return <span>{degrees}° {minutes}'{showSeconds ? secondenTekst : ""}</span>;
};
