import React from "react";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import {DecimalDegree} from "../DecimalDegree";
import {Config} from "../../../Config";
import {Markdown} from "../Markdown";

export interface PlanetCardProps {
    planet: any;
}

export const PlanetInHouseCard: React.FC<PlanetCardProps> = (props) => {
    const {
        planet,
        longitude,
        degreeInSign,
        house,
        sign,
        speed,
        planetInHouseText,
        planetInZodiacSignText
    } = props.planet;

    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>

                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            <DecimalDegree decimalDegree={degreeInSign}/>
                        </Typography>
                    </Grid>
                </Grid>

                <Typography variant="h5" component="div">
                    {Config.labels.bodies[planet]} in {Config.labels.signs[sign]}
                </Typography>

                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {Config.labels.houses[house]}
                </Typography>

                <Typography variant="body2">
                    {planetInHouseText?.[Config.defaultLanguage]?.intro}
                </Typography>
                <Typography variant="body2" dangerouslySetInnerHTML={{__html: planetInHouseText?.[Config.defaultLanguage]?.description}}/>
            </CardContent>

            {/*<CardActions>*/}
            {/*    <Button size="small">Learn More</Button>*/}
            {/*</CardActions>*/}
        </Card>
    );
};
