import React, {useEffect} from "react";
import {useRemark} from "react-remark";

export interface MarkdownProps {

}

export const Markdown: React.FC<MarkdownProps> = (props) => {
    const {children} = props;

    const [reactContent, setMarkdownSource] = useRemark();

    useEffect(() => {
        if (children) {
            setMarkdownSource(children as string);
        }
    }, [children]);

    return reactContent;
};
