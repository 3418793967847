import React, {ReactNode} from "react";
import {useInput} from "react-admin";
import {Editor} from "./Editor";

export interface TabbedInputFieldProps {
    name: string;
    locale: string;
    label?: ReactNode;
    disabled?: boolean;
}

export const TabbedEditorField: React.FC<TabbedInputFieldProps> = (props) => {
    const {name, locale, label, disabled} = props;

    const input = useInput({source: name.replace("${locale}", locale)});

    const {isSubmitted} = input.formState;
    const {invalid, isTouched, error} = input.fieldState;

    return (
        <Editor
            {...input.field}
            // error={(isTouched || isSubmitted) && invalid}
            // helperText={(isTouched || isSubmitted) && invalid ? error : ''}
        />
    );
};
