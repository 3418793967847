export const Config = {
    defaultLanguage: "en",

    labels: {
        bodies: {
            SUN: "Sun",
            MOON: "Moon",
            MERCURY: "Mercury",
            VENUS: "Venus",
            MARS: "Mars",
            JUPITER: "Jupiter",
            SATURN: "Saturn",
            URANUS: "Uranus",
            NEPTUNE: "Neptune",
            PLUTO: "Pluto",
            MEAN_NODE: "Mean Node",
            TRUE_NODE: "North Node / True Node", // North Node
            MEAN_APOGEE: "Lilith", // Lilith
            OSCULATING_APOG: "Pars Fortuna", // Part of Fortune
            EARTH: "Earth",
            CHIRON: "Chiron",
            PHOLUS: "Pholus",
            CERES: "Ceres",
            PALLAS: "Pallas",
            JUNO: "Juno",
            VESTA: "Vesta",
            INTERPOLATED_LUNAR_APOGEE: "Interpolated Lunar Apogee",
            INTERPOLATED_LUNAR_PERIGEE: "Interpolated Lunar Perigee",

            // Angles
            ASCENDANT: "Ascendant (As)",
            DESCENDANT: "Descendant (Ds)",
            MIDHEAVEN: "Midheaven (Mc)",
            IMUM_COELI: "Imum Coeli (Ic)"
        } as Record<string, string>,
        houses: {
            HOUSE_1: "House 1",
            HOUSE_2: "House 2",
            HOUSE_3: "House 3",
            HOUSE_4: "House 4",
            HOUSE_5: "House 5",
            HOUSE_6: "House 6",
            HOUSE_7: "House 7",
            HOUSE_8: "House 8",
            HOUSE_9: "House 9",
            HOUSE_10: "House 10",
            HOUSE_11: "House 11",
            HOUSE_12: "House 12"
        } as Record<string, string>,
        signs: {
            ARIES: "Aries",
            TAURUS: "Taurus",
            GEMINI: "Gemini",
            CANCER: "Cancer",
            LEO: "Leo",
            VIRGO: "Virgo",
            LIBRA: "Libra",
            SCORPIO: "Scorpio",
            SAGITTARIUS: "Sagittarius",
            CAPRICORN: "Capricorn",
            AQUARIUS: "Aquarius",
            PISCES: "Pisces"
        } as Record<string, string>,
        aspects: {
            CONJUNCTION: "Conjunction",
            SEXTILE: "Sextile",
            SQUARE: "Square",
            TRINE: "Trine",
            OPPOSITION: "Opposition",

            INCONJUNCT: "Inconjunct",
            SEMISQUARE: "Semisquare",
            TRIOCTILE: "Trioctile",
            SEMISEXTILE: "Semisextile",
            QUINTILE: "Quintile",
            BIQUINTILE: "Biquintile"
        } as Record<string, string>
    }
};

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};
