import React, {ReactNode} from "react";
import {Link, useCreatePath, useResourceContext, Button} from "react-admin";
import {useRecordContext} from "ra-core";
import {stringify} from "querystring";
import Iconify from "../theme/components/Iconify";
import {ButtonProps as MuiButtonProps} from "@mui/material/Button/Button";

export interface CopyButtonProps extends MuiButtonProps {
    label?: string;
    icon?: ReactNode;
    transform?: Function;
}

const defaultIcon = <Iconify icon="carbon:copy"/>;

export const CopyButton = (props: CopyButtonProps) => {
    const {
        label = "Copy",
        icon = defaultIcon,
        transform,
        ...rest
    } = props;

    const resource = useResourceContext(props as any);
    const record = useRecordContext(props);
    const createPath = useCreatePath();
    const pathname = createPath({resource, type: "create"});

    const createTo = () => {
        if (record) {
            console.log(record);
            const source = transform?.(record) || record;
            delete source.id;

            return {
                pathname,
                search: stringify({
                    source: JSON.stringify(source),
                })
            };
        } else {
            return pathname;
        }
    };

    return (
        <Button
            component={Link}
            to={createTo()}
            label={label}
            {...rest as any}
        >
            {icon as any}
        </Button>
    );
};
