import React, {useState} from "react";
import {Alert, Button, IconButton, InputAdornment, Link, Stack} from "@mui/material";
import RHFTextField from "../../components/form/RHFTextField";
import Iconify from "../../theme/components/Iconify";
import {RHFCheckbox} from "../../components/form/RHFCheckbox";
import {NavLink as RouterLink} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import * as Yup from "yup";
import {Auth} from "aws-amplify";
import {useNotify} from "react-admin";
import {useNavigate} from "react-router";

export interface LoginFormValues {
    email: string;
    password: string;
    remember: boolean;
}

export const LoginForm: React.FC = (props) => {
    // const { login } = useAuth();
    //
    // const isMountedRef = useIsMountedRef();

    const notify = useNotify();
    const navigate = useNavigate();

    const [verify, setVerify] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const defaultValues: LoginFormValues = {
        email: "",
        password: "",
        remember: false
    };

    const methods = useForm<LoginFormValues & {afterSubmit?: string}>({
        resolver: yupResolver(LoginSchema),
        defaultValues
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: {errors, isSubmitting},
        getValues
    } = methods;

    const values = getValues();

    const onSubmit = async (data: any) => {
        if (verify) {
            await Auth.confirmSignUp(`email:${data.email}`, data.code);

            notify("Your account has been verified. You can now log in.");
            setVerify(false);
        } else {
            try {
                await Auth.signIn({
                    username: `email:${data.email}`,
                    password: data.password
                });

                navigate("/");
            } catch (error: any) {
                if (error.code === "UserNotConfirmedException") {
                    setVerify(true);
                }
                console.error(error);
                // reset();
                // if (isMountedRef.current) {
                //     setError('afterSubmit', error);
                // }
            }
        }
    };

    const onResend = async () => {
        await Auth.resendSignUp(`email:${values.email}`);
        notify("Verification code resent.");
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

                    {!verify && (
                        <>
                            <RHFTextField name="email" label="Email address"/>

                            <RHFTextField
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </>
                    )}

                    {verify && (
                        <>
                            <RHFTextField name="code" label="Verification code"/>
                        </>
                    )}
                </Stack>

                {!verify && (
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
                        <RHFCheckbox name="remember" label="Remember me"/>
                        <Link component={RouterLink} variant="subtitle2" to="/forgot-password">
                            Forgot password?
                        </Link>
                    </Stack>
                )}

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{mb: 1}}>
                    {verify ? "Verify" : "Login"}
                </LoadingButton>

                {verify && <Button fullWidth size="large" type="button" variant="contained" onClick={onResend}>
                    Resend verification code
                </Button>}
            </form>
        </FormProvider>
    );
};
