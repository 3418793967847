import React, {useMemo} from "react";

import "./App.css";
import "rapidoc";
import {Admin, CustomRoutes, defaultTheme, Resource} from "react-admin";
import {BrowserRouter, Route} from "react-router-dom";
import {AstroApiDataProvider} from "./api/DataProvider";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {HelmetProvider} from 'react-helmet-async';
import {NatalTryItOutPage} from "./admin/pages/NatalTryItOutPage";
import {SynastryTryItOutPage} from "./admin/pages/SynastryTryItOutPage";
import DashboardLayout from "./admin/theme/layouts/dashboard";
import palette from "./admin/theme/theme/palette";
import typography from "./admin/theme/theme/typography";
import shadows, {customShadows} from "./admin/theme/theme/shadows";
import {createTheme} from "@mui/material/styles";
import componentsOverride from "./admin/theme/theme/overrides";
import {ThemeOptions} from "@mui/material/styles/createTheme";
import {resourceConfig} from "./admin/resources/ResourceConfig";
import {ResourceCreate, ResourceEdit, ResourceList} from "./admin/resources/ResourceComponents";
import {DashboardPage} from "./admin/pages/DashboardPage";
import {NatalHoroscopeInfoPage} from "./admin/pages/NatalHoroscopeInfoPage";
import {AstroApiAuthProvider} from "./admin/auth/AstroApiAuthProvider";
import {LoginPage} from "./admin/auth/LoginPage";
import {RegisterPage} from "./admin/auth/RegisterPage";
import "./admin/auth/AmplifyConfig";
import {SetupPage} from "./admin/auth/SetupPage";
import {AccountPage} from "./admin/auth/AccountPage";
import {CompatibilityTryItOutPage} from "./admin/pages/CompatibilityTryItOutPage";
import {TransitTryItOutPage} from "./admin/pages/TransitTryItOutPage";
import {CompositeTryItOutPage} from "./admin/pages/CompositeTryItOutPage";
import {LunarReturnTryItOutPage} from "./admin/pages/LunarReturnTryItOutPage";
import {SolarReturnTryItOutPage} from "./admin/pages/SolarReturnTryItOutPage";
import {ChineseTryItOutPage} from "./admin/pages/ChineseTryItOutPage";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "rapi-doc": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

const baseUrl = window.__RUNTIME_CONFIG__.API_URL;
// const baseUrl = "http://localhost:8080";
// const baseUrl = "http://astroapiv2test-env.eba-jepe4iiz.eu-west-1.elasticbeanstalk.com";
const apiUrl = `${baseUrl}`;
const docsUrl = `${baseUrl}/v3/api-docs`;

const astroApiDataProvider = new AstroApiDataProvider(apiUrl);
const astroApiAuthProvider = new AstroApiAuthProvider(astroApiDataProvider);

const theme = {
    ...defaultTheme,
    sidebar: {
        width: 300,
        closedWidth: 70
    },
};


export const App = () => {
    const themeOptions = useMemo(
        () => ({
            palette,
            shape: {borderRadius: 8},
            typography,
            shadows,
            customShadows
        } as ThemeOptions),
        []
    );

    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <HelmetProvider>
                <BrowserRouter>
                    <Admin dataProvider={astroApiDataProvider} layout={DashboardLayout} theme={theme}
                           authProvider={astroApiAuthProvider} loginPage={LoginPage} requireAuth>
                        <CustomRoutes noLayout>
                            <Route path="/register" element={<RegisterPage/>}/>
                            <Route path="/setup" element={<SetupPage/>}/>
                        </CustomRoutes>

                        <CustomRoutes>
                            <Route path="/" element={<DashboardPage/>}/>
                            <Route path="/account" element={<AccountPage/>}/>

                            <Route path="/natal-info" element={<NatalHoroscopeInfoPage/>}/>

                            <Route path="/natal-try-it-out" element={<NatalTryItOutPage/>}/>
                            <Route path="/synastry-try-it-out" element={<SynastryTryItOutPage/>}/>
                            <Route path="/compatibility-try-it-out" element={<CompatibilityTryItOutPage/>}/>
                            <Route path="/transit-try-it-out" element={<TransitTryItOutPage/>}/>
                            <Route path="/composite-try-it-out" element={<CompositeTryItOutPage/>}/>
                            <Route path="/lunarreturn-try-it-out" element={<LunarReturnTryItOutPage/>}/>
                            <Route path="/solarreturn-try-it-out" element={<SolarReturnTryItOutPage/>}/>
                            <Route path="/chinese-horoscope-try-it-out" element={<ChineseTryItOutPage/>}/>
                        </CustomRoutes>

                        {Object.keys(resourceConfig).map(resource => {
                            let create;
                            if (resourceConfig[resource]?.CreateComponent) {
                                create = resourceConfig[resource].CreateComponent;
                            } else if (resourceConfig[resource]?.createFields) {
                                create = (props: any) => <ResourceCreate {...props}
                                                                         config={resourceConfig[resource]}/>;
                            }

                            return (
                                <Resource key={resource}
                                          name={resource}
                                          list={(props) => <ResourceList {...props} config={resourceConfig[resource]}/>}
                                          create={create}
                                          edit={(props) => <ResourceEdit {...props} config={resourceConfig[resource]}/>}
                                          options={{label: resourceConfig[resource].pageTitle}}/>
                            );
                        })}
                    </Admin>
                </BrowserRouter>
            </HelmetProvider>
        </LocalizationProvider>
    );
}
