import React, {useState} from 'react';
// material
import {styled} from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import {CssBaseline} from "@mui/material";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    height: "100%",
    minHeight: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({theme}) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout(props: any) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <CssBaseline/>

            <RootStyle>
                <DashboardNavbar onOpenSidebar={() => setOpen(true)}/>
                <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)}/>
                <MainStyle>
                    {props.children}
                </MainStyle>
            </RootStyle>
        </>
    );
}
