import React from "react";
import {Title, useDataProvider} from "react-admin";
import {useMutation} from "react-query";
import {Button, Card, CardContent, CardHeader, Container, Grid, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {PlanetInSignCard} from "../components/horoscope/PlanetInSignCard";
import {HouseCard} from "../components/horoscope/HouseCard";
import {AspectCard} from "../components/horoscope/AspectCard";
import {AddressSearchInput} from "../components/AddressSearchInput";
import Image from "../theme/components/Image";
import {Markdown} from "../components/Markdown";
import {Config} from "../../Config";
import {DateTimePickerInput} from "../input/DateTimePickerInput";

interface FormValues {
    date?: string;
    year?: string;
}

export const ChineseTryItOutPage: React.FC = (props) => {
    const {control, register, handleSubmit, setValue, watch, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            date: "",
            year: undefined
        }
    });

    const dataProvider = useDataProvider();

    const {mutate, isLoading, data} = useMutation((params: any) => dataProvider.calculateChineseHoroscopeYearly(params));

    const onSubmit = (data: FormValues) => {
        const params = {
            "date": dayjs(data.date!).set("seconds", 0).set("ms", 0).toISOString(),
            "year": data.year!
        };

        mutate(params);
    };

    return (
        <Container>
            <Card sx={{marginTop: 4, marginBottom: 4}}>
                <Title title="test"/>
                <CardHeader title="Calculate Chinese Horoscope"/>

                <CardContent>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <DateTimePickerInput
                                name="date"
                                rules={{ required: true }}
                                control={control}/>
                            <br/><br/>

                            <TextField label="Forecast year" type="number" InputLabelProps={{shrink: true}} inputProps={{step: "any"}} {...register("year")}/>
                            <br/>

                            <Button type="submit">Calculate</Button>
                        </form>
                    </div>
                </CardContent>
            </Card>

            <h2>Result</h2>

            {/*{(data as any)?.data?.chineseZodiacSign}*/}

            {(data as any)?.data?.text?.[Config.defaultLanguage]?.intro}

            {(data as any)?.data?.text?.[Config.defaultLanguage]?.description && (
                <Markdown>
                    {(data as any).data.text[Config.defaultLanguage].description}
                </Markdown>
            )}

        </Container>
    );
};
