import {DataProvider, fetchUtils} from "react-admin";
import {
    CreateParams,
    CreateResult,
    DeleteManyParams,
    DeleteManyResult,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyReferenceParams,
    GetManyReferenceResult,
    GetManyResult,
    GetOneParams,
    GetOneResult,
    RaRecord,
    UpdateManyParams,
    UpdateManyResult,
    UpdateParams,
    UpdateResult
} from "ra-core";
// import {Options} from "ra-core/src/dataProvider/fetch";
import {Auth} from "aws-amplify";
import {resourceConfig} from "../admin/resources/ResourceConfig";

export class AstroApiDataProvider implements DataProvider {

    private readonly resourceToUrlMap: Record<string, string> = {
        "natal-texts-planet-in-sign": "horoscope/natal/texts/planet-in-sign",
        "natal-texts-planet-in-house": "horoscope/natal/texts/planet-in-house",
        "natal-texts-sign-on-house-cusp": "horoscope/natal/texts/sign-on-house-cusp",
        "natal-texts-aspects": "horoscope/natal/texts/aspects"
    };

    private readonly httpClient = async (url: string, options: any = {}) => {
        if (!options.headers) {
            options.headers = new Headers({Accept: "application/json"});
        }

        const authSession = await Auth.currentSession();

        const accessToken = authSession.getAccessToken()
        const jwt = accessToken.getJwtToken()

        options.headers.set("Authorization", `Bearer ${jwt}`);

        return fetchUtils.fetchJson(url, options);
    };

    constructor(private readonly baseUrl: string) {

    }

    async create<RecordType extends RaRecord>(resource: string, params: CreateParams): Promise<CreateResult<RecordType>> {
        const url = `${this.baseUrl}/${this.resourceToUrl(resource)}`;
        const options = {
            method: "POST",
            body: JSON.stringify(params.data)
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json};
        });
    }

    async delete<RecordType extends RaRecord>(resource: string, params: DeleteParams<RecordType>): Promise<DeleteResult<RecordType>> {
        const url = `${this.baseUrl}/${this.resourceToUrl(resource)}/${params.id}`;
        const options = {
            method: "DELETE"
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json};
        });
    }

    async deleteMany<RecordType extends RaRecord>(resource: string, params: DeleteManyParams<RecordType>): Promise<DeleteManyResult<RecordType>> {
        for (const id of params.ids) {
            await this.delete(resource, {id});
        }

        return {};
    }

    async getList<RecordType extends RaRecord>(resource: string, params: GetListParams): Promise<GetListResult<RecordType>> {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const url = `${this.baseUrl}/${this.resourceToUrl(resource)}?page=${page - 1}&size=${perPage}&sort=${field},${order}`;
        const options: any = {};

        return this.httpClient(url, options).then(({json}) => {
            if (!json.hasOwnProperty("totalElements")) {
                throw new Error(
                    "The numberOfElements property must be must be present in the Json response"
                );
            }

            return {
                data: json.content,
                total: parseInt(json.totalElements, 10)
            };
        });
    }

    async getMany<RecordType extends RaRecord>(resource: string, params: GetManyParams): Promise<GetManyResult<RecordType>> {
        const query = {
            filter: JSON.stringify({id: params.ids})
        };
        let idStr = "";
        const queryString = params.ids.map((id) => idStr + `id=${id}`);
        const url = `${this.baseUrl}/${this.resourceToUrl(resource)}?${idStr}`;
        const options = {};

        return this.httpClient(url, options).then(({json}) => {
            return {data: json.content};
        });
    }

    async getManyReference<RecordType extends RaRecord>(resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult<RecordType>> {
        const {page, perPage} = params.pagination;
        const url = `${this.baseUrl}/${this.resourceToUrl(resource)}?page=${page}&size=${perPage}`;
        const options: any = {};

        return this.httpClient(url, options).then(({json}) => {
            return {data: json};
        });
    }

    async getOne<RecordType extends RaRecord>(resource: string, params: GetOneParams): Promise<GetOneResult<RecordType>> {
        const url = `${this.baseUrl}/${this.resourceToUrl(resource)}/${params.id}`;
        const options: any = {};

        return this.httpClient(url, options).then(({json}) => {
            return {data: json};
        });
    }

    async update<RecordType extends RaRecord>(resource: string, params: UpdateParams): Promise<UpdateResult<RecordType>> {
        const url = `${this.baseUrl}/${this.resourceToUrl(resource)}/${params.id}`;
        const options: any = {
            method: "PUT",
            body: JSON.stringify(params.data)
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json};
        });
    }

    async updateMany<RecordType extends RaRecord>(resource: string, params: UpdateManyParams): Promise<UpdateManyResult<RecordType>> {
        for (const id of params.ids) {
            await this.update(resource, {id, previousData: params.data, data: params.data, meta: params.meta});
        }

        return {};
    }

    async calculateChineseHoroscopeBasic(params: any) {
        const url = `${this.baseUrl}/horoscope/chinese-basic/calculate`;
        const options = {
            method: "POST",
            body: JSON.stringify(params)
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json}
        });
    }

    async calculateChineseHoroscopeYearly(params: any) {
        const url = `${this.baseUrl}/horoscope/chinese-yearly/calculate`;
        const options = {
            method: "POST",
            body: JSON.stringify(params)
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json}
        });
    }

    async calculateNatal(params: any) {
        const url = `${this.baseUrl}/horoscope/natal/calculate`;
        const options = {
            method: "POST",
            body: JSON.stringify(params)
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json}
        });
    }

    async calculateTransit(params: any) {
        const url = `${this.baseUrl}/horoscope/transit/calculate`;
        const options = {
            method: "POST",
            body: JSON.stringify(params)
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json}
        });
    }

    async calculateLunarReturn(params: any) {
        const url = `${this.baseUrl}/horoscope/lunarreturn/calculate`;
        const options = {
            method: "POST",
            body: JSON.stringify(params)
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json}
        });
    }

    async calculateSolarReturn(params: any) {
        const url = `${this.baseUrl}/horoscope/solarreturn/calculate`;
        const options = {
            method: "POST",
            body: JSON.stringify(params)
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json}
        });
    }

    async calculateComposite(params: any) {
        const url = `${this.baseUrl}/horoscope/composite/calculate`;
        const options = {
            method: "POST",
            body: JSON.stringify(params)
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json}
        });
    }

    async calculateSynastry(params: any) {
        const url = `${this.baseUrl}/horoscope/synastry/calculate`;
        const options = {
            method: "POST",
            body: JSON.stringify(params)
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json}
        });
    }

    async calculateCompatibility(params: any) {
        const url = `${this.baseUrl}/horoscope/compatibility/calculate`;
        const options = {
            method: "POST",
            body: JSON.stringify(params)
        };

        return this.httpClient(url, options).then(({json}) => {
            return {data: json}
        });
    }

    async getMe() {
        const url = `${this.baseUrl}/me`;

        const {json} = await this.httpClient(url);

        return {
            data: json
        };
    }

    async accountSetup() {
        const url = `${this.baseUrl}/account/setup`;

        await this.httpClient(url, {method: "POST"});

        return {
            data: {}
        };
    }

    async accountSetupStripe() {
        const url = `${this.baseUrl}/account/billing/stripe`;

        await this.httpClient(url, {method: "POST"});

        return {
            data: {}
        };
    }

    async accountStripeBillingPortal() {
        const url = `${this.baseUrl}/account/billing/stripe/portal`;

        const {json} = await this.httpClient(url, {redirect: "manual"});

        return {
            data: {
                url: json.url
            }
        };
    }

    async locationSearch(query: string) {
        const url = `${this.baseUrl}/geocode/search?query=${query}`;

        const {json} = await this.httpClient(url);

        return {
            data: json
        };
    }

    private resourceToUrl(resource: string) {
        return this.resourceToUrlMap[resource] || resourceConfig[resource].apiUrl || resource;
    }

}
