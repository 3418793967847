import React, {ReactNode, useState} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {TabbedEditorField} from "./TabbedEditorField";
import {InputLabel} from "@mui/material";

const TabPanel: React.FC<{ visible: boolean }> = (props) => {
    if (!props.visible) {
        return null;
    }

    return (
        <div>
            {props.children}
        </div>
    );
};

export interface TabbedInputProps {
    name: string;
    disabled?: boolean;
    label?: ReactNode;
}

export const TabbedEditorInput: React.FC<TabbedInputProps> = (props) => {
    const {name, disabled, label} = props;

    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <>
            <InputLabel>{label}</InputLabel>

            <Tabs value={selectedIndex} onChange={(_, index) => setSelectedIndex(index)}>
                <Tab label="English"/>
                <Tab label="Dutch"/>
            </Tabs>

            <TabPanel visible={selectedIndex === 0}>
                <TabbedEditorField name={name} locale="en" disabled={disabled} label={label}/>
            </TabPanel>
            <TabPanel visible={selectedIndex === 1}>
                <TabbedEditorField name={name} locale="nl" disabled={disabled} label={label}/>
            </TabPanel>
        </>
    );
};
