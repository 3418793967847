import {FC, memo} from "react";
import {useRecordContext} from "ra-core";
import get from "lodash/get";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Badge, Box} from "@mui/material";

export const EntityNameField: FC<any> = memo(props => {
    const { className, source, emptyText, ...rest } = props;
    const record = useRecordContext(props);
    const value = get(record, source as any);

    const renderBadge = () => {
        const {ownerType, ownerId} = record.owner;
        if (ownerType == "SYSTEM") {
            return (
                <Box sx={{ml: 2, backgroundColor: "text.disabled", borderRadius: 2, color: "white", px: 1}} component="span">
                    <Typography component="span" variant="caption">System</Typography>
                </Box>
            );
        }

        return (
            <Box sx={{ml: 2, backgroundColor: "info.main", borderRadius: 2, color: "white", px: 1}} component="span">
                <Typography component="span" variant="caption">My Version</Typography>
            </Box>
        );
    };

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...rest}
        >
            {value}

            {renderBadge()}
        </Typography>
    );
});
