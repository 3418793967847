import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";

export interface ApiKeyDialogProps {
    apiKey?: string;
    onClose?: () => any;
}

export const ApiKeyDialog: React.FC<ApiKeyDialogProps> = (props) => {
    const {apiKey, onClose} = props;

    if (!apiKey) {
        return null;
    }

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>New API key</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You can find your newly created API key here. Be sure to record it as you will only be able to view it now.

                    <Box sx={theme => ({
                        backgroundColor: theme.palette.grey.A700,
                        color: theme.palette.getContrastText(theme.palette.grey.A700),
                        borderRadius: 1,
                        mt: 2,
                        px: 1,
                        py: 1,
                        overflowY: "auto"
                    })}>
                        <pre>
                            <code>
                                {apiKey}
                            </code>
                        </pre>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}
