import {Helmet} from 'react-helmet-async';
import {forwardRef, ReactNode} from 'react';
// @mui
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

export interface PageProps {
    title: string;
    meta?: any;
    children?: ReactNode;
}

const Page = forwardRef<HTMLDivElement, PageProps>(({children, title = '', meta, ...other}, ref) => (
    <>
        <Helmet>
            <title>{`${title}`}</title>
            {meta}
        </Helmet>

        <Box sx={{height: "100%"}} ref={ref} {...other}>
            {children}
        </Box>
    </>
));

export default Page;
