import React, {ReactNode} from "react";
import {Config} from "../../Config";
import {
    DateField,
    EditProps,
    EmailField,
    ReferenceInput,
    SelectInput,
    SortPayload,
    TextField,
    TextInput,
    DateTimeInput
} from "react-admin";
import {TekstMetIntroFields} from "./TekstMetIntroFields";
import {LanguageTab} from "../components/LanguageTab";
import {TextEditorInput} from "../input/TextEditorInput";
import {CreateProps} from "ra-ui-materialui/src/types";
import {ApiKeyCreate} from "./ApiKeyCreate";
import {EntityNameField} from "./EntityNameField";

export interface UpdateFieldsOptions {
    disabled: boolean;
}

export interface ResourceConfigItem {
    pageTitle: string;
    title: ReactNode;
    apiUrl: string;
    listFields: ReactNode[];
    CreateComponent?: React.FC;
    defaultSort?: SortPayload;
    createFields?: ReactNode;
    createProps?: CreateProps;
    EditFieldComponent?: React.ComponentClass;
    editFields?: (options: UpdateFieldsOptions) => ReactNode;
    editProps?: EditProps;
}

export const resourceConfig: Record<string, ResourceConfigItem> = {
    "api-keys": {
        pageTitle: "API Keys",
        title: "API Key",
        apiUrl: "api-key",
        listFields: [
            <TextField source="name" label="Name"/>
        ],
        CreateComponent: ApiKeyCreate,
        editFields: ({disabled}) => (
            <TextInput source="name" disabled/>
        )
    },
    "entities": {
        pageTitle: "Entities",
        title: "Entities",
        apiUrl: "entities",
        listFields: [
            <TextField source="urn" label="Urn"/>
        ],
        editFields: () => (
            <>

            </>
        )
    },
    "users": {
        pageTitle: "Users",
        title: "Users",
        apiUrl: "users",
        listFields: [
            <EmailField source="emailAddress" label="E-mail address"/>,
            <TextField source="subscriptionPlan" label="Plan"/>,
            <TextField source="subscriptionPeriod" label="Period"/>,
            <TextField source="status" label="Status"/>,
            <DateField source="expiresAt" label="Expiration date"/>
        ],
        editFields: ({disabled}) => (
            <>
                <TextInput source="emailAddress" disabled/>

                <SelectInput source="subscriptionPlan" choices={[
                    {id: "BASIC", name: "Basic"},
                    {id: "GOLD", name: "Gold"},
                    {id: "PREMIUM", name: "Premium"},
                    {id: "DELUXE", name: "Deluxe"}
                ]}/>

                <SelectInput source="status" choices={[
                    {id: "TRIALING", name: "Trial"},
                    {id: "ACTIVE_SUBSCRIPTION", name: "Active subscription"},
                    {id: "EXPIRED_SUBSCRIPTION", name: "Expired subscription"}
                ]}/>

                <DateTimeInput source="expiresAt" label="Expiration date"/>
            </>
        )
    },
    "houses": {
        pageTitle: "Houses",
        title: "Houses",
        apiUrl: "houses",
        defaultSort: {field: "urn", order: "ASC"},
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.name`} label="Name"/>
        ],
        createFields: (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} label="Name"/>
                        <TextEditorInput source={`translations.${locale}.description`}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        ),
        editFields: ({disabled}) => (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} disabled={disabled} label="Name"/>
                        <TextEditorInput source={`translations.${locale}.description`} disabled={disabled}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        )
    },
    "planets": {
        pageTitle: "Planets",
        title: "Planets",
        apiUrl: "planets",
        defaultSort: {field: "urn", order: "ASC"},
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.name`} label="Naam"/>
        ],
        createFields: (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} label="Name"/>
                        <TextEditorInput source={`translations.${locale}.description`}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        ),
        editFields: ({disabled}) => (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} disabled={disabled} label="Name"/>
                        <TextEditorInput source={`translations.${locale}.description`} disabled={disabled}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        )
    },
    "zodiac-signs": {
        pageTitle: "Zodiac signs",
        title: "Zodiac signs",
        apiUrl: "zodiac-signs",
        defaultSort: {field: "urn", order: "ASC"},
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.name`} label="Naam"/>
        ],
        createFields: (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} label="Name"/>
                        <TextEditorInput source={`translations.${locale}.description`}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        ),
        editFields: ({disabled}) => (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} disabled={disabled} label="Name"/>
                        <TextEditorInput source={`translations.${locale}.description`} disabled={disabled}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        )
    },
    "chinese-zodiac-signs": {
        pageTitle: "Chinese zodiac signs",
        title: "Chinese zodiac signs",
        apiUrl: "chinese-zodiac-signs",
        defaultSort: {field: "urn", order: "ASC"},
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.name`} label="Naam"/>
        ],
        createFields: (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} label="Name"/>
                        <TextEditorInput source={`translations.${locale}.description`}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        ),
        editFields: ({disabled}) => (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} disabled={disabled} label="Name"/>
                        <TextEditorInput source={`translations.${locale}.description`} disabled={disabled}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        )
    },
    "aspects": {
        pageTitle: "Aspects",
        title: "Aspects",
        apiUrl: "aspects",
        defaultSort: {field: "urn", order: "ASC"},
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.name`} label="Naam"/>
        ],
        createFields: (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} label="Naam"/>
                        <TextEditorInput source={`translations.${locale}.description`}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        ),
        editFields: ({disabled}) => (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} disabled={disabled} label="Naam"/>
                        <TextEditorInput source={`translations.${locale}.description`} disabled={disabled}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        )
    },
    "elements": {
        pageTitle: "Elements",
        title: "Elements",
        apiUrl: "elements",
        defaultSort: {field: "urn", order: "ASC"},
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.name`} label="Naam"/>
        ],
        createFields: (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} label="Naam"/>
                        <TextEditorInput source={`translations.${locale}.description`}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        ),
        editFields: ({disabled}) => (
            <LanguageTab>
                {locale => (
                    <>
                        <TextInput source={`translations.${locale}.name`} disabled={disabled} label="Naam"/>
                        <TextEditorInput source={`translations.${locale}.description`} disabled={disabled}
                                         label="Description"/>
                    </>
                )}
            </LanguageTab>
        )
    },
    "zodiac-points": {
        pageTitle: "Zodiac Points",
        title: "Zodiac Points",
        apiUrl: "zodiac-points",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`zodiacSign.translations.${Config.defaultLanguage}.name`} label="Zodiac Sign"/>,
            <TextField source={`strength`} label="Strength"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Zodiac sign" source="zodiacSignId" reference="zodiac-signs">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <SelectInput source="strength" choices={[
                    {id: "WEAK", name: "Weak"},
                    {id: "STRONG", name: "Strong"}
                ]}/>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="zodiacSign.id" reference="zodiac-signs"
                                disabled={disabled}>
                    <SelectInput label="Zodiac sign" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <SelectInput source="strength" choices={[
                    {id: "WEAK", name: "Weak"},
                    {id: "STRONG", name: "Strong"}
                ]}/>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    zodiacSignId: data.zodiacSign.id,
                    translations: data.translations
                };
            }
        }
    },
    "element-strength": {
        pageTitle: "Element Strength",
        title: "Element Strength",
        apiUrl: "horoscope/element-strength/texts",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`element.translations.${Config.defaultLanguage}.name`} label="Element"/>,
            <TextField source={`strength`} label="Strength"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Element" source="elementId" reference="elements">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <SelectInput source="strength" choices={[
                    {id: "WEAK", name: "Weak"},
                    {id: "STRONG", name: "Strong"}
                ]}/>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="element.id" reference="elements"
                                disabled={disabled}>
                    <SelectInput label="Element" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <SelectInput source="strength" choices={[
                    {id: "WEAK", name: "Weak"},
                    {id: "STRONG", name: "Strong"}
                ]}/>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    elementId: data.element.id,
                    translations: data.translations
                };
            }
        }
    },
    "horoscope-compatibility-texts": {
        pageTitle: "Horoscope Compatibility",
        title: "Horoscope Compatibility",
        apiUrl: "horoscope/compatibility/texts",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`zodiacSign1.translations.${Config.defaultLanguage}.name`} label="Zodiac Sign 1"/>,
            <TextField source={`zodiacSign2.translations.${Config.defaultLanguage}.name`} label="Zodiac Sign 2"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Zodiac sign 1" source="zodiacSign1Id" reference="zodiac-signs">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput label="Zodiac sign 2" source="zodiacSign2Id" reference="zodiac-signs">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="zodiacSign1.id" reference="zodiac-signs"
                                disabled={disabled}>
                    <SelectInput label="Zodiac sign" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput source="zodiacSign2.id" reference="zodiac-signs"
                                disabled={disabled}>
                    <SelectInput label="Zodiac sign" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    zodiacSign1Id: data.zodiacSign1.id,
                    zodiacSign2Id: data.zodiacSign2.id,
                    translations: data.translations
                };
            }
        }
    },
    "natal-texts-basic": {
        pageTitle: "Natal Horoscope - Basic",
        title: "Natal Horoscope - Basic",
        apiUrl: "horoscope/natal/texts/basic",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`zodiacSign.translations.${Config.defaultLanguage}.name`} label="Zodiac Sign"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Zodiac sign" source="zodiacSignId" reference="zodiac-signs">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="zodiacSign.id" reference="zodiac-signs"
                                disabled={disabled}>
                    <SelectInput label="Zodiac sign" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    zodiacSignId: data.zodiacSign.id,
                    translations: data.translations
                };
            }
        }
    },
    "natal-texts-planet-in-sign": {
        pageTitle: "Natal horoscope - Planet in zodiac sign",
        title: "Natal horoscope - Planet in zodiac sign",
        apiUrl: "horoscope/natal/texts/planet-in-sign",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet.translations.${Config.defaultLanguage}.name`} label="Planet"/>,
            <TextField source={`zodiacSign.translations.${Config.defaultLanguage}.name`} label="Zodiac Sign"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet" source="planetId" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput label="Zodiac sign" source="zodiacSignId" reference="zodiac-signs">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="planet.id" reference="planets" disabled={disabled}>
                    <SelectInput label="Planet" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput source="zodiacSign.id" reference="zodiac-signs"
                                disabled={disabled}>
                    <SelectInput label="Zodiac sign" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planetId: data.planet.id,
                    zodiacSignId: data.zodiacSign.id,
                    translations: data.translations
                };
            }
        }
    },
    "natal-texts-planet-in-house": {
        pageTitle: "Natal horoscope - Planet in house",
        title: "Natal horoscope - Planet in house",
        apiUrl: "horoscope/natal/texts/planet-in-house",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet.translations.${Config.defaultLanguage}.name`} label="Planet"/>,
            <TextField source={`house.translations.${Config.defaultLanguage}.name`} label="House"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet" source="planetId" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput label="House" source="houseId" reference="houses">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <LanguageTab>
                {locale => (
                    <>
                        <ReferenceInput source="planet.id" reference="planets" disabled={disabled}>
                            <SelectInput label="Planet" optionText={`translations.${Config.defaultLanguage}.name`}/>
                        </ReferenceInput>

                        <ReferenceInput source="house.id" reference="houses" disabled={disabled}>
                            <SelectInput label="House" optionText={`translations.${Config.defaultLanguage}.name`}/>
                        </ReferenceInput>

                        <TekstMetIntroFields disabled={disabled} locale={locale}/>
                    </>
                )}
            </LanguageTab>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planetId: data.planet.id,
                    houseId: data.house.id,
                    translations: data.translations
                };
            }
        }
    },
    "natal-texts-sign-on-house-cusp": {
        pageTitle: "Natal horoscope - Zodiac sign on house cusp",
        title: "Natal horoscope - Zodiac sign on house cusp",
        apiUrl: "horoscope/natal/texts/sign-on-house-cusp",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`zodiacSign.translations.${Config.defaultLanguage}.name`} label="Zodiac Sign"/>,
            <TextField source={`house.translations.${Config.defaultLanguage}.name`} label="House"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Zodiac sign" source="zodiacSignId" reference="zodiac-signs">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput label="House" source="houseId" reference="houses">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="zodiacSign.id" reference="zodiac-signs"
                                disabled={disabled}>
                    <SelectInput label="Zodiac sign" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput source="house.id" reference="houses" disabled={disabled}>
                    <SelectInput label="House" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    zodiacSignId: data.zodiacSign.id,
                    houseId: data.house.id,
                    translations: data.translations
                };
            }
        }
    },
    "natal-texts-aspects": {
        pageTitle: "Natal horoscope - Aspects",
        title: "Natal horoscope - Aspects",
        apiUrl: "horoscope/natal/texts/aspects",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet1.translations.${Config.defaultLanguage}.name`} label="Planet 1"/>,
            <TextField source={`planet2.translations.${Config.defaultLanguage}.name`} label="Planet 2"/>,
            <TextField source={`aspect.translations.${Config.defaultLanguage}.name`} label="Aspect"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet 1" source="planet1Id" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput label="Planet 2" source="planet2Id" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput label="Aspect" source="aspectId" reference="aspects">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="planet1.id" parse={(value) => {
                    console.log(value);
                    return value;
                }} reference="planets" disabled={disabled}>
                    <SelectInput label="Planet 1" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput source="planet2.id" reference="planets" disabled={disabled}>
                    <SelectInput label="Planet 2" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput source="aspect.id" reference="aspects" disabled={disabled}>
                    <SelectInput label="Aspect" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planet1Id: data.planet1.id,
                    planet2Id: data.planet2.id,
                    aspectId: data.aspect.id,
                    translations: data.translations
                };
            }
        }
    },


    "transit-texts-planet-in-house": {
        pageTitle: "Transit horoscope - Planet in house",
        title: "Transit horoscope - Planet in house",
        apiUrl: "horoscope/transit/texts/planet-in-house",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet.translations.${Config.defaultLanguage}.name`} label="Planet"/>,
            <TextField source={`house.translations.${Config.defaultLanguage}.name`} label="House"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet" source="planetId" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput label="House" source="houseId" reference="houses">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <LanguageTab>
                {locale => (
                    <>
                        <ReferenceInput source="planet.id" reference="planets" disabled={disabled}>
                            <SelectInput label="Planet" optionText={`translations.${Config.defaultLanguage}.name`}/>
                        </ReferenceInput>

                        <ReferenceInput source="house.id" reference="houses" disabled={disabled}>
                            <SelectInput label="House" optionText={`translations.${Config.defaultLanguage}.name`}/>
                        </ReferenceInput>

                        <TekstMetIntroFields disabled={disabled} locale={locale}/>
                    </>
                )}
            </LanguageTab>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planetId: data.planet.id,
                    houseId: data.house.id,
                    translations: data.translations
                };
            }
        }
    },
    "transit-texts-aspects": {
        pageTitle: "Transit horoscope - Aspects",
        title: "Transit horoscope - Aspects",
        apiUrl: "horoscope/transit/texts/aspects",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet1.translations.${Config.defaultLanguage}.name`} label="Planet 1"/>,
            <TextField source={`planet2.translations.${Config.defaultLanguage}.name`} label="Planet 2"/>,
            <TextField source={`aspect.translations.${Config.defaultLanguage}.name`} label="Aspect"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet 1" source="planet1Id" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput label="Planet 2" source="planet2Id" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput label="Aspect" source="aspectId" reference="aspects">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="planet1.id" parse={(value) => {
                    console.log(value);
                    return value;
                }} reference="planets" disabled={disabled}>
                    <SelectInput label="Planet 1" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput source="planet2.id" reference="planets" disabled={disabled}>
                    <SelectInput label="Planet 2" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput source="aspect.id" reference="aspects" disabled={disabled}>
                    <SelectInput label="Aspect" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planet1Id: data.planet1.id,
                    planet2Id: data.planet2.id,
                    aspectId: data.aspect.id,
                    translations: data.translations
                };
            }
        }
    },

    "synastry-texts-aspects": {
        pageTitle: "Synastry horoscope - Aspects",
        title: "Synastry horoscope - Aspects",
        apiUrl: "horoscope/synastry/texts/aspects",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet1.translations.${Config.defaultLanguage}.name`} label="Planet 1"/>,
            <TextField source={`planet2.translations.${Config.defaultLanguage}.name`} label="Planet 2"/>,
            <TextField source={`aspect.translations.${Config.defaultLanguage}.name`} label="Aspect"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet 1" source="planet1Id" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput label="Planet 2" source="planet2Id" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput label="Aspect" source="aspectId" reference="aspects">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="planet1.id" parse={(value) => {
                    return value;
                }} reference="planets" disabled={disabled}>
                    <SelectInput label="Planet 1" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput source="planet2.id" reference="planets" disabled={disabled}>
                    <SelectInput label="Planet 2" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput source="aspect.id" reference="aspects" disabled={disabled}>
                    <SelectInput label="Aspect" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planet1Id: data.planet1.id,
                    planet2Id: data.planet2.id,
                    aspectId: data.aspect.id,
                    translations: data.translations
                };
            }
        }
    },


    "composite-texts-planet-in-house": {
        pageTitle: "Composite horoscope - Planet in house",
        title: "Composite horoscope - Planet in house",
        apiUrl: "horoscope/composite/texts/planet-in-house",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet.translations.${Config.defaultLanguage}.name`} label="Planet"/>,
            <TextField source={`house.translations.${Config.defaultLanguage}.name`} label="House"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet" source="planetId" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput label="House" source="houseId" reference="houses">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <LanguageTab>
                {locale => (
                    <>
                        <ReferenceInput source="planet.id" reference="planets" disabled={disabled}>
                            <SelectInput label="Planet" optionText={`translations.${Config.defaultLanguage}.name`}/>
                        </ReferenceInput>

                        <ReferenceInput source="house.id" reference="houses" disabled={disabled}>
                            <SelectInput label="House" optionText={`translations.${Config.defaultLanguage}.name`}/>
                        </ReferenceInput>

                        <TekstMetIntroFields disabled={disabled} locale={locale}/>
                    </>
                )}
            </LanguageTab>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planetId: data.planet.id,
                    houseId: data.house.id,
                    translations: data.translations
                };
            }
        }
    },
    "composite-texts-aspects": {
        pageTitle: "Composite horoscope - Aspects",
        title: "Composite horoscope - Aspects",
        apiUrl: "horoscope/composite/texts/aspects",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet1.translations.${Config.defaultLanguage}.name`} label="Planet 1"/>,
            <TextField source={`planet2.translations.${Config.defaultLanguage}.name`} label="Planet 2"/>,
            <TextField source={`aspect.translations.${Config.defaultLanguage}.name`} label="Aspect"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet 1" source="planet1Id" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput label="Planet 2" source="planet2Id" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput label="Aspect" source="aspectId" reference="aspects">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="planet1.id" parse={(value) => {
                    console.log(value);
                    return value;
                }} reference="planets" disabled={disabled}>
                    <SelectInput label="Planet 1" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput source="planet2.id" reference="planets" disabled={disabled}>
                    <SelectInput label="Planet 2" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>
                <ReferenceInput source="aspect.id" reference="aspects" disabled={disabled}>
                    <SelectInput label="Aspect" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planet1Id: data.planet1.id,
                    planet2Id: data.planet2.id,
                    aspectId: data.aspect.id,
                    translations: data.translations
                };
            }
        }
    },


    "solarreturn-point-in-sign": {
        pageTitle: "Solar Return horoscope - Point in zodiac sign",
        title: "Solar Return horoscope - Point in zodiac sign",
        apiUrl: "horoscope/solarreturn/texts/point-in-sign",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet.translations.${Config.defaultLanguage}.name`} label="Planet"/>,
            <TextField source={`zodiacSign.translations.${Config.defaultLanguage}.name`} label="Zodiac Sign"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet" source="planetId" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput label="Zodiac sign" source="zodiacSignId" reference="zodiac-signs">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="planet.id" reference="planets" disabled={disabled}>
                    <SelectInput label="Planet" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput source="zodiacSign.id" reference="zodiac-signs"
                                disabled={disabled}>
                    <SelectInput label="Zodiac sign" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planetId: data.planet.id,
                    zodiacSignId: data.zodiacSign.id,
                    translations: data.translations
                };
            }
        }
    },
    "solarreturn-point-in-house": {
        pageTitle: "Solar Return horoscope - Point in house",
        title: "Solar Return horoscope - Point in house",
        apiUrl: "horoscope/solarreturn/texts/point-in-house",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet.translations.${Config.defaultLanguage}.name`} label="Planet"/>,
            <TextField source={`house.translations.${Config.defaultLanguage}.name`} label="House"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet" source="planetId" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput label="House" source="houseId" reference="houses">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <LanguageTab>
                {locale => (
                    <>
                        <ReferenceInput source="planet.id" reference="planets" disabled={disabled}>
                            <SelectInput label="Planet" optionText={`translations.${Config.defaultLanguage}.name`}/>
                        </ReferenceInput>

                        <ReferenceInput source="house.id" reference="houses" disabled={disabled}>
                            <SelectInput label="House" optionText={`translations.${Config.defaultLanguage}.name`}/>
                        </ReferenceInput>

                        <TekstMetIntroFields disabled={disabled} locale={locale}/>
                    </>
                )}
            </LanguageTab>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planetId: data.planet.id,
                    houseId: data.house.id,
                    translations: data.translations
                };
            }
        }
    },

    "lunarreturn-point-in-sign": {
        pageTitle: "Lunar Return horoscope - Point in zodiac sign",
        title: "Lunar Return horoscope - Point in zodiac sign",
        apiUrl: "horoscope/lunarreturn/texts/point-in-sign",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet.translations.${Config.defaultLanguage}.name`} label="Planet"/>,
            <TextField source={`zodiacSign.translations.${Config.defaultLanguage}.name`} label="Zodiac Sign"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet" source="planetId" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput label="Zodiac sign" source="zodiacSignId" reference="zodiac-signs">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="planet.id" reference="planets" disabled={disabled}>
                    <SelectInput label="Planet" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput source="zodiacSign.id" reference="zodiac-signs"
                                disabled={disabled}>
                    <SelectInput label="Zodiac sign" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planetId: data.planet.id,
                    zodiacSignId: data.zodiacSign.id,
                    translations: data.translations
                };
            }
        }
    },
    "lunarreturn-point-in-house": {
        pageTitle: "Lunar Return horoscope - Point in house",
        title: "Lunar Return horoscope - Point in house",
        apiUrl: "horoscope/lunarreturn/texts/point-in-house",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`planet.translations.${Config.defaultLanguage}.name`} label="Planet"/>,
            <TextField source={`house.translations.${Config.defaultLanguage}.name`} label="House"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Planet" source="planetId" reference="planets">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <ReferenceInput label="House" source="houseId" reference="houses">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <LanguageTab>
                {locale => (
                    <>
                        <ReferenceInput source="planet.id" reference="planets" disabled={disabled}>
                            <SelectInput label="Planet" optionText={`translations.${Config.defaultLanguage}.name`}/>
                        </ReferenceInput>

                        <ReferenceInput source="house.id" reference="houses" disabled={disabled}>
                            <SelectInput label="House" optionText={`translations.${Config.defaultLanguage}.name`}/>
                        </ReferenceInput>

                        <TekstMetIntroFields disabled={disabled} locale={locale}/>
                    </>
                )}
            </LanguageTab>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    planetId: data.planet.id,
                    houseId: data.house.id,
                    translations: data.translations
                };
            }
        }
    },

    "chinese-horoscope-basic": {
        pageTitle: "Chinese Horoscope - Basic",
        title: "Chinese Horoscope - Basic",
        apiUrl: "horoscope/chinese/texts/basic",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`chineseZodiacSign.translations.${Config.defaultLanguage}.name`} label="Chinese Zodiac Sign"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Chinese zodiac sign" source="chineseZodiacSignId" reference="chinese-zodiac-signs">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="chineseZodiacSign.id" reference="chinese-zodiac-signs"
                                disabled={disabled}>
                    <SelectInput label="Chinese zodiac sign" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    chineseZodiacSignId: data.chineseZodiacSign.id,
                    translations: data.translations
                };
            }
        }
    },

    "chinese-horoscope-yearly": {
        pageTitle: "Chinese Horoscope - Yearly",
        title: "Chinese Horoscope - Yearly",
        apiUrl: "horoscope/chinese/texts/yearly",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`chineseZodiacSign.translations.${Config.defaultLanguage}.name`} label="Chinese Zodiac Sign"/>,
            <TextField source="year" label="Year"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Chinese zodiac sign" source="chineseZodiacSignId" reference="chinese-zodiac-signs">
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <TextInput type="number" source="year"/>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput source="chineseZodiacSign.id" reference="chinese-zodiac-signs"
                                disabled={disabled}>
                    <SelectInput label="Chinese zodiac sign" optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <TextInput type="number" source="year"/>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    chineseZodiacSignId: data.chineseZodiacSign.id,
                    translations: data.translations,
                    year: data.year
                };
            }
        }
    },



    "forecast-weekly": {
        pageTitle: "Weekly Forecast",
        title: "Weekly Forecast",
        apiUrl: "horoscope/forecast/texts/weekly",
        listFields: [
            <EntityNameField source={`translations.${Config.defaultLanguage}.intro`} label="Intro"/>,
            <TextField source={`chineseZodiacSign.translations.${Config.defaultLanguage}.name`} label="Chinese Zodiac Sign"/>
        ],
        createFields: (
            <>
                <ReferenceInput label="Entity" source="entityId" reference="entities" perPage={200}>
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editFields: ({disabled}) => (
            <>
                <ReferenceInput label="Entity" source="entityId" reference="entities"
                                disabled={disabled} perPage={200}>
                    <SelectInput optionText={`translations.${Config.defaultLanguage}.name`}/>
                </ReferenceInput>

                <LanguageTab>
                    {locale => <TekstMetIntroFields locale={locale}/>}
                </LanguageTab>
            </>
        ),
        editProps: {
            transform: (data, options) => {
                return {
                    entityId: data.entities.id
                };
            }
        }
    },
};
