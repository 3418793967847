import React, {ReactNode} from "react";
import TextField from "@mui/material/TextField";
import {useInput} from "react-admin";

export interface TabbedInputFieldProps {
    name: string;
    locale: string;
    label?: ReactNode;

    disabled?: boolean;
}

export const TabbedInputField: React.FC<TabbedInputFieldProps> = (props) => {
    const {name, locale, label, disabled} = props;

    const input = useInput({source: name.replace("${locale}", locale)});

    const {isSubmitted} = input.formState;
    const {invalid, isTouched, error} = input.fieldState;

    return (
        <TextField
            {...input.field}
            label={label}
            disabled={disabled}
            error={(isTouched || isSubmitted) && invalid}
            helperText={(isTouched || isSubmitted) && invalid ? error : ''}
        />
    );
};
