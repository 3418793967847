import React from "react";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import {DecimalDegree} from "../DecimalDegree";
import {Config} from "../../../Config";
import {Markdown} from "../Markdown";

export interface SolarLunarPointCardProps {
    point: any;
}

export const SolarLunarPointCard: React.FC<SolarLunarPointCardProps> = (props) => {
    const {
        angle,
        degrees,
        degreeInSign,
        text,
        zodiacSign
    } = props.point;

    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>

                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            <DecimalDegree decimalDegree={degrees}/>
                        </Typography>
                    </Grid>
                </Grid>

                <Typography variant="h5" component="div">
                    {Config.labels.bodies[angle]} in {Config.labels.signs[zodiacSign]}
                </Typography>

                <Typography variant="body2">
                    {text?.[Config.defaultLanguage]?.intro}
                </Typography>
                <Typography variant="body2" dangerouslySetInnerHTML={{__html: text?.[Config.defaultLanguage]?.description}}/>
            </CardContent>
        </Card>
    );
};
