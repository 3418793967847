import React from "react";
import {useDataProvider} from "react-admin";
import {useMutation} from "react-query";
import {Button, Card, CardContent, CardHeader, Container, Grid, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {PlanetInSignCard} from "../components/horoscope/PlanetInSignCard";
import {HouseCard} from "../components/horoscope/HouseCard";
import {AspectCard} from "../components/horoscope/AspectCard";
import {AddressSearchInput} from "../components/AddressSearchInput";
import Image from "../theme/components/Image";
import Page from "../theme/components/Page";
import {DateTimePickerInput} from "../input/DateTimePickerInput";
import {PlanetInHouseCard} from "../components/horoscope/PlanetInHouseCard";
import {SolarLunarPointCard} from "../components/horoscope/SolarLunarPoint";

interface FormValues {
    date?: string;
    timezone?: string;
    latitude?: number;
    longitude?: number;

    returnLatitude?: number;
    returnLongitude?: number;
    returnTimezone?: string;
}

export const LunarReturnTryItOutPage: React.FC = (props) => {
    const {control, register, handleSubmit, setValue, watch, formState: {errors}} = useForm<FormValues>({
        defaultValues: {
            date: ""
        }
    });

    const dataProvider = useDataProvider();

    const {mutate, isLoading, data} = useMutation((params: any) => dataProvider.calculateLunarReturn(params));

    const onSubmit = (data: FormValues) => {
        const params = {
            "dateTime": dayjs(data.date!).set("seconds", 0).set("ms", 0).toISOString(),
            "timezone": data.timezone!,
            "latitude": data.latitude!,
            "longitude": data.longitude!,
            "returnLatitude": data.returnLatitude,
            "returnLongitude": data.returnLongitude,
            "returnTimezone": data.returnTimezone
        };

        mutate(params);
    };

    const renderAngle = (angle: any) => {
        if (!["ASCENDANT", "MIDHEAVEN"].includes(angle.angle)) {
            return null;
        }

        return (
            <Grid item xs={12}>
                <SolarLunarPointCard point={angle}/>
            </Grid>
        );
    };

    const renderPlanetInHouse = (planet: any) => {
        return (
            <Grid item xs={12}>
                <PlanetInHouseCard planet={planet}/>
            </Grid>
        );
    };

    const renderAspect = (aspect: any) => {
        return (
            <Grid item xs={12}>
                <AspectCard aspect={aspect}/>
            </Grid>
        );
    };

    return (
        <Page title="Calculate Lunar Return Horoscope">
            <Container>
                <h1>Calculate Lunar Return Horoscope</h1>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Card sx={{marginTop: 4, marginBottom: 4}}>
                        <CardHeader title="Person Birth Details"/>

                        <CardContent>
                            <div>

                                <DateTimePickerInput
                                    name="date"
                                    rules={{ required: true }}
                                    control={control}/>
                                <br/><br/>

                                <AddressSearchInput onSelect={(place) => {
                                    if (place) {
                                        setValue("latitude", place.lat);
                                        setValue("longitude", place.lng);
                                        setValue("timezone", place.timeZone);
                                    } else {
                                        setValue("latitude", undefined);
                                        setValue("longitude", undefined);
                                        setValue("timezone", undefined);
                                    }
                                }}/>
                                <br/>

                                <Grid container spacing={2}>
                                    <Grid item>
                                        <TextField label="Latitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("latitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Longitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("longitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Timezone" InputLabelProps={{shrink: true}}
                                                   defaultValue="Europe/Brussels" {...register("timezone")}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    </Card>

                    <Card sx={{marginTop: 4, marginBottom: 4}}>
                        <CardHeader title="Where will you spend your birthday?"/>

                        <CardContent>
                            <div>
                                <AddressSearchInput onSelect={(place) => {
                                    if (place) {
                                        setValue("returnLatitude", place.lat);
                                        setValue("returnLongitude", place.lng);
                                        setValue("returnTimezone", place.timeZone);
                                    } else {
                                        setValue("returnLatitude", undefined);
                                        setValue("returnLongitude", undefined);
                                        setValue("returnTimezone", undefined);
                                    }
                                }}/>
                                <br/>

                                <Grid container spacing={2}>
                                    <Grid item>
                                        <TextField label="Latitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("returnLatitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Longitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("returnLongitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Timezone" InputLabelProps={{shrink: true}}
                                                   defaultValue="Europe/Brussels" {...register("returnTimezone")}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    </Card>

                    <Button type="submit">Calculate</Button>
                </form>

                <h2>Wheel chart</h2>
                <Grid container>
                    <Grid item xs={12}>
                        {(data as any)?.data?.chartUrl && (
                            <Image src={(data as any)?.data?.chartUrl}/>
                        )}
                    </Grid>
                </Grid>

                <h2>Lunar Points</h2>
                <Grid container spacing={2}>
                    {(data as any)?.data?.angles?.map?.((item: any) => renderAngle(item))}
                </Grid>

                <h2>Planets and advanced points in Houses</h2>
                <Grid container spacing={2}>
                    {(data as any)?.data?.planets?.map?.((item: any) => renderPlanetInHouse(item))}
                </Grid>
            </Container>
        </Page>
    );
};
