import React, {useState} from "react";
import Page from "../theme/components/Page";
import {Container, Stack, Typography} from "@mui/material";
import {
    Create,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useCreate,
    useRedirect
} from "react-admin";
import {ApiKeyDialog} from "../components/ApiKeyDialog";
import {useNavigate} from "react-router";

export const ApiKeyCreate: React.FC = (props) => {
    const [create] = useCreate();
    const navigate = useNavigate();
    const [apiKeyValue, setApiKeyValue] = useState<string>();

    const postSave = async (data: any) => {
        const apiKey = await create("api-keys", {data}, {
            returnPromise: true
        });

        setApiKeyValue(apiKey.tokenValue);
    };

    const onClose = () => {
        setApiKeyValue(undefined);
        navigate("/api-keys");
    };

    return (
        <>
            <Page title="Aspecten">
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Create an API key
                        </Typography>
                    </Stack>

                    <Create>
                        <SimpleForm onSubmit={postSave} toolbar={
                            <Toolbar>
                                <SaveButton label="Save"/>
                            </Toolbar>
                        }>
                            <TextInput source="name"/>
                        </SimpleForm>
                    </Create>
                </Container>
            </Page>
            <ApiKeyDialog apiKey={apiKeyValue} onClose={onClose}/>
        </>
    );
};
