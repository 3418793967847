import React from "react";
import {useDataProvider} from "react-admin";
import {useMutation} from "react-query";
import {Button, Card, CardContent, CardHeader, Container, Grid, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {PlanetInSignCard} from "../components/horoscope/PlanetInSignCard";
import {HouseCard} from "../components/horoscope/HouseCard";
import {AspectCard} from "../components/horoscope/AspectCard";
import {AddressSearchInput} from "../components/AddressSearchInput";
import Image from "../theme/components/Image";
import Page from "../theme/components/Page";
import {DateTimePickerInput} from "../input/DateTimePickerInput";

interface FormValues {
    questionerDate?: string;
    questionerTimezone?: string;
    questionerLatitude?: number;
    questionerLongitude?: number;

    partnerDate?: string;
    partnerTimezone?: string;
    partnerLatitude?: number;
    partnerLongitude?: number;
}

export const SynastryTryItOutPage: React.FC = (props) => {
    const {control, register, handleSubmit, setValue, watch, formState: {errors}} = useForm<FormValues>({
        defaultValues: {
            questionerDate: "",
            partnerDate: ""
        }
    });

    const dataProvider = useDataProvider();

    const {mutate, isLoading, data} = useMutation((params: any) => dataProvider.calculateSynastry(params));

    const onSubmit = (data: FormValues) => {
        const params = {
            "questionerDateTime": dayjs(data.questionerDate!).set("seconds", 0).set("ms", 0).toISOString(),
            "questionerTimezone": data.questionerTimezone!,
            "questionerLatitude": data.questionerLatitude!,
            "questionerLongitude": data.questionerLongitude!,

            "partnerDateTime": dayjs(data.partnerDate!).set("seconds", 0).set("ms", 0).toISOString(),
            "partnerTimezone": data.partnerTimezone!,
            "partnerLatitude": data.partnerLatitude!,
            "partnerLongitude": data.partnerLongitude!
        };

        mutate(params);
    };

    const renderAspect = (aspect: any) => {
        return (
            <Grid item xs={12}>
                <AspectCard aspect={aspect}/>
            </Grid>
        );
    };

    return (
        <Page title="Calculate Synastry Horoscope">
            <Container>
                <h1>Calculate Synastry Horoscope</h1>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Card sx={{marginTop: 4, marginBottom: 4}}>
                        <CardHeader title="Questioner Person Birth Details"/>

                        <CardContent>
                            <div>

                                <DateTimePickerInput
                                    name="questionerDate"
                                    rules={{ required: true }}
                                    control={control}/>
                                <br/><br/>

                                <AddressSearchInput onSelect={(place) => {
                                    if (place) {
                                        setValue("questionerLatitude", place.lat);
                                        setValue("questionerLongitude", place.lng);
                                        setValue("questionerTimezone", place.timeZone);
                                    } else {
                                        setValue("questionerLatitude", undefined);
                                        setValue("questionerLongitude", undefined);
                                        setValue("questionerTimezone", undefined);
                                    }
                                }}/>
                                <br/>

                                <Grid container spacing={2}>
                                    <Grid item>
                                        <TextField label="Latitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("questionerLatitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Longitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("questionerLongitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Timezone" InputLabelProps={{shrink: true}}
                                                   defaultValue="Europe/Brussels" {...register("questionerTimezone")}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    </Card>

                    <Card sx={{marginTop: 4, marginBottom: 4}}>
                        <CardHeader title="Partner Event Details"/>

                        <CardContent>
                            <div>

                                <DateTimePickerInput
                                    name="partnerDate"
                                    rules={{ required: true }}
                                    control={control}/>
                                <br/><br/>

                                <AddressSearchInput onSelect={(place) => {
                                    if (place) {
                                        setValue("partnerLatitude", place.lat);
                                        setValue("partnerLongitude", place.lng);
                                        setValue("partnerTimezone", place.timeZone);
                                    } else {
                                        setValue("partnerLatitude", undefined);
                                        setValue("partnerLongitude", undefined);
                                        setValue("partnerTimezone", undefined);
                                    }
                                }}/>
                                <br/>

                                <Grid container spacing={2}>
                                    <Grid item>
                                        <TextField label="Latitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("partnerLatitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Longitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("partnerLongitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Timezone" InputLabelProps={{shrink: true}}
                                                   defaultValue="Europe/Brussels" {...register("partnerTimezone")}/>
                                    </Grid>
                                </Grid>

                                <Button type="submit">Calculate</Button>
                            </div>
                        </CardContent>
                    </Card>
                </form>

                <h2>Aspects Questioner</h2>
                <Grid container spacing={2}>
                    {(data as any)?.data?.aspectsQuestioner?.concat()?.sort((a: any, b: any) => Object.keys(b.text || {}).length - Object.keys(a.text || {}).length)?.map?.((item: any) => renderAspect(item))}
                </Grid>

                <h2>Aspects Partner</h2>
                <Grid container spacing={2}>
                    {(data as any)?.data?.aspectsPartner?.concat()?.sort((a: any, b: any) => Object.keys(b.text || {}).length - Object.keys(a.text || {}).length)?.map?.((item: any) => renderAspect(item))}
                </Grid>

                <h2>Wheel chart questioner</h2>
                <Grid container>
                    <Grid item xs={12}>
                        {(data as any)?.data?.chartUrlQuestioner && (
                            <Image src={(data as any)?.data?.chartUrlQuestioner}/>
                        )}
                    </Grid>
                </Grid>

                <h2>Wheel chart partner</h2>
                <Grid container>
                    <Grid item xs={12}>
                        {(data as any)?.data?.chartUrlPartner && (
                            <Image src={(data as any)?.data?.chartUrlPartner}/>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};
