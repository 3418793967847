import React, {useEffect} from "react";
import Page from "../theme/components/Page";
import {Button, Card, CardContent, Container, Grid, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useGetIdentity} from "react-admin";
import {useNavigate} from "react-router";
import dayjs from "dayjs";
import {NavLink} from "react-router-dom";

const RootStyle = styled(Card)(({theme}) => ({
    boxShadow: 'none',
    textAlign: 'center',
    backgroundColor: (theme.palette.primary as any).lighter,
    [theme.breakpoints.up('md')]: {
        height: '100%',
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

const STATUS_TO_TEXT: Record<string, (plan: string) => string> = {
    TRIALING: (plan: string) => `You are currently enjoying a trial for the ${plan} subscription plan.`,
    EXPIRED_SUBSCRIPTION: (plan: string) => `Your subscription for ${plan} has expired. Please renew the subscription on the billing page.`,
    ACTIVE_SUBSCRIPTION: (plan: string) => `You are currently enjoying the ${plan} subscription plan.`
};

export const DashboardPage: React.FC<any> = (props) => {
    const {identity} = useGetIdentity();

    const navigate = useNavigate();

    useEffect(() => {
        if (identity?.id === "not-authenticated") {
            navigate("/setup");
        }
    }, [identity]);

    return (
        <Page title="Dashboard">
            <Container maxWidth={"xl"}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <RootStyle>
                            <CardContent
                                sx={{
                                    p: {md: 0},
                                    pt: {md: 5},
                                    pl: {md: 5},
                                    color: 'grey.800',
                                }}
                            >
                                <Typography gutterBottom variant="h4">
                                    Welcome back, {identity?.fullName}
                                </Typography>

                                <Typography variant="body2" sx={{pb: {xs: 3, xl: 5}, maxWidth: 480, mx: 'auto'}}>
                                    This is your personal admin portal for managing your AstroAPI.
                                </Typography>

                                <Typography variant="body2" sx={{pb: {xs: 3, xl: 5}, maxWidth: 480, mx: 'auto'}}>
                                    AstroAPI.cloud is still in beta. If you encounter any issues or if you have suggestions, please do share them with us. We are constantly improving.
                                    If you are already an active customer on api.bloom.be (version 1), you can contact us with your details. We will set up an account on AstroAPI.cloud for you with the same details so you can use both.
                                </Typography>
                            </CardContent>
                        </RootStyle>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Card>
                            <CardContent sx={{color: 'grey.800'}}>
                                <Typography gutterBottom variant="h4">
                                    Getting started
                                </Typography>

                                <Typography variant="body2">
                                    Get started by creating your first <NavLink to="/api-keys">API Key</NavLink> and making your first request. Check out the documentation <a href={window.__RUNTIME_CONFIG__.DOCS_URL}>here</a> on how to get started with that.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Card>
                            <CardContent sx={{color: 'grey.800'}}>
                                <Typography gutterBottom variant="h4">
                                    Your subscription
                                </Typography>

                                <Typography variant="body2" sx={{mb: 4}}>
                                    {identity && STATUS_TO_TEXT[identity.status]?.(identity.subscriptionPlan)} It is set to expire at <strong>{dayjs(identity?.expiresAt).format("LLL")}</strong>.
                                </Typography>

                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Button component={NavLink} to="/account">
                                            Cancel plan
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button component={NavLink} to="/account" variant="outlined">
                                            Change plan
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button component={NavLink} to="/account" variant="outlined">
                                            Update payment information
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};
