import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {matchPath, NavLink as RouterLink, useLocation} from 'react-router-dom';
// material
import {alpha, styled, useTheme} from '@mui/material/styles';
import {
    Box,
    Collapse,
    List,
    ListItemButton,
    ListItemButtonProps,
    ListItemIcon,
    ListItemText,
    ListSubheader
} from '@mui/material';
//
import Iconify from './Iconify';
import {NavListRoot} from './NavList';
import {usePermissions} from "react-admin";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props: ListItemButtonProps & {component?: any; to?: string; onClick?: Function}) => <ListItemButton
    disableGutters {...props} />)(({theme}) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    // textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
    item: PropTypes.object,
    active: PropTypes.func,
};

// @ts-ignore
function NavItem({item, active}) {
    const theme = useTheme();

    const isActiveRoot = active(item.path);

    const {title, path, icon, info, children} = item;

    const [open, setOpen] = useState(isActiveRoot);

    const handleOpen = () => {
        setOpen((prev: boolean) => !prev);
    };

    const activeRootStyle = {
        color: 'primary.main',
        fontWeight: 'fontWeightMedium',
        bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    };

    const activeSubStyle = {
        color: 'text.primary',
        fontWeight: 'fontWeightMedium',
    };

    if (children) {
        return (
            <>
                <ListItemStyle
                    onClick={handleOpen}
                    sx={{
                        ...(isActiveRoot && activeRootStyle),
                    }}
                >
                    <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                    <ListItemText disableTypography primary={title}/>
                    {info && info}
                    <Iconify
                        icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                        sx={{width: 16, height: 16, ml: 1}}
                    />
                </ListItemStyle>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {children.map((item: any) => {
                            const {title, path} = item;
                            const isActiveSub = active(path);

                            return (
                                <ListItemStyle
                                    key={title}
                                    component={RouterLink}
                                    to={path}
                                    sx={{
                                        ...(isActiveSub && activeSubStyle),
                                    }}
                                >
                                    <ListItemIconStyle>
                                        <Box
                                            component="span"
                                            sx={{
                                                width: 4,
                                                height: 4,
                                                display: 'flex',
                                                borderRadius: '50%',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                bgcolor: 'text.disabled',
                                                transition: (theme) => theme.transitions.create('transform'),
                                                ...(isActiveSub && {
                                                    transform: 'scale(2)',
                                                    bgcolor: 'primary.main',
                                                }),
                                            }}
                                        />
                                    </ListItemIconStyle>
                                    <ListItemText disableTypography primary={title}/>
                                </ListItemStyle>
                            );
                        })}
                    </List>
                </Collapse>
            </>
        );
    }

    return (
        <ListItemStyle
            component={RouterLink}
            to={path}
            sx={{
                ...(isActiveRoot && activeRootStyle),
            }}
        >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText disableTypography primary={title}/>
            {info && info}
        </ListItemStyle>
    );
}

NavSection.propTypes = {
    navConfig: PropTypes.array,
};

// @ts-ignore
export default function NavSection({navConfig, ...other}) {
    const {pathname} = useLocation();

    const match = (path: any) => (path ? !!matchPath({path, end: false}, pathname) : false);

    return (
        <Box {...other}>
            <List disablePadding sx={{p: 1}}>
                {navConfig.map((item: any) => (
                    <NavItem key={item.title} item={item} active={match}/>
                ))}
            </List>
        </Box>
    );
}

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
    ({theme}) => ({
        ...theme.typography.overline,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        color: theme.palette.text.primary,
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shorter,
        }),
    })
);

export const NavSectionWithHeadings: React.FC<{ navConfig: any; isCollapse?: boolean }> = (props) => {
    const {navConfig, isCollapse, ...other} = props;

    const {permissions = []} = usePermissions();

    const checkPermissions = (group: any) => {
        if (group.permissions) {
            return group.permissions.every((item: string) => permissions.includes(item));
        } else {
            return true;
        }
    };

    return (
        <Box {...other}>
            {navConfig.filter(checkPermissions).map((group: any) => {
                if (group.items && checkPermissions(group)) {
                    return (
                        <List key={group.subheader} disablePadding sx={{px: 2}}>
                            <ListSubheaderStyle
                                sx={{
                                    ...(isCollapse && {
                                        opacity: 0,
                                    }),
                                }}
                            >
                                {group.subheader}
                            </ListSubheaderStyle>

                            {group.items.filter(checkPermissions).map((list: any) => (
                                <NavListRoot key={list.title} list={list} isCollapse={isCollapse}/>
                            ))}
                        </List>
                    );
                } else if (checkPermissions(group)) {
                    return (
                        <NavListRoot key={group.title} list={group} isCollapse={isCollapse}/>
                    );
                }
            })}
        </Box>
    );
};
