import {AuthProvider, UserIdentity} from "react-admin";
import {Auth} from "aws-amplify";
import {AstroApiDataProvider} from "../../api/DataProvider";

export class AstroApiAuthProvider implements AuthProvider {

    constructor(private readonly dataProvider: AstroApiDataProvider) {
    }

    async checkAuth(params: any): Promise<void> {
        if (window.location.pathname === "/register") {
            return;
        }

        await Auth.currentAuthenticatedUser();
    }

    async checkError(error: any): Promise<void> {

    }

    async getIdentity(): Promise<UserIdentity> {
        try {
            const authenticatedUser = await Auth.currentAuthenticatedUser();
            if (!authenticatedUser) {
                return {
                    id: "not-authenticated"
                };
            }

            const {data: me} = await this.dataProvider.getMe();

            return {
                id: me.id,
                isSetup: me.isSetup,
                subscriptionPeriod: me.subscriptionPeriod,
                subscriptionPlan: me.subscriptionPlan,
                activePaymentMethods: me.activePaymentMethods,
                status: me.status,
                expiresAt: me.expiresAt,

                idpId: authenticatedUser.id,
                fullName: authenticatedUser.attributes.name,
                email: authenticatedUser.attributes.email
            };
        } catch (error) {
            return {
                id: "not-authenticated"
            };
        }
    }

    async getPermissions(params: any): Promise<any> {
        try {
            // const authenticatedUser = await Auth.currentAuthenticatedUser();
            // const groups = authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"];
            //
            // return groups || [];

            const authenticatedUser = await Auth.currentAuthenticatedUser();
            if (!authenticatedUser) {
                return {
                    id: "not-authenticated"
                };
            }

            const {data: me} = await this.dataProvider.getMe();

            return me?.permissions || [];
        } catch (error) {
            return [];
        }
    }

    async getRoles(): Promise<any> {

    }

    async login(params: any): Promise<any> {
        const {provider} = params;

        await Auth.federatedSignIn({provider: provider});
    }

    async logout(params: any): Promise<void | false | string> {
        return Auth.signOut();
    }

}
