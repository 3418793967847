import React from "react";
import {styled} from "@mui/material/styles";
import {Alert, Box, Button, Card, Container, Link, Stack, Tooltip, Typography} from "@mui/material";
import Page from "../theme/components/Page";
import Logo from "../theme/components/Logo";
import useResponsive from "../theme/hooks/useResponsive";
import Image from "../theme/components/Image";
import {NavLink as RouterLink} from "react-router-dom";
import {LoginForm} from "./components/LoginForm";
import {Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";
import Iconify from "../theme/components/Iconify";
import {Images} from "../../static/images/Images";
import {useLogin} from "react-admin";
import {useNavigate} from "react-router";

export const LoginPage: React.FC = (props) => {
    // const { method } = useAuth();
    // const login = useLogin();
    const navigate = useNavigate();

    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");

    const onLogin = async ({provider}: {provider: any}) => {
        await Auth.federatedSignIn({provider});

        // navigate("/account");
    };

    return (
        <Page title="Login">
            <RootStyle>
                <HeaderStyle>
                    <Logo/>
                    {smUp && (
                        <Typography variant="body2" sx={{mt: {md: -2}}}>
                            Don't have an account? {""}
                            <Link variant="subtitle2" component={RouterLink} to="/register">
                                Get started
                            </Link>
                        </Typography>
                    )}
                </HeaderStyle>

                {mdUp && (
                    <SectionStyle>
                        <Typography variant="h3" sx={{px: 5, mt: 10, mb: 5}}>
                            Hi, welcome back. Access your personal dashboard here.
                        </Typography>
                        <Image
                            alt="login"
                            src={Images.loginIllustration}
                        />
                    </SectionStyle>
                )}

                <Container maxWidth="md">
                    <ContentStyle>
                        <Stack direction="row" alignItems="center" sx={{mb: 5}}>
                            <Box sx={{flexGrow: 1}}>
                                <Typography variant="h4" gutterBottom>
                                    Sign in to AstroAPI
                                </Typography>
                                <Typography sx={{color: "text.secondary"}}>
                                    Login using your social account, or choose to use your email address.
                                </Typography>
                            </Box>
                        </Stack>

                        <Stack direction="row" spacing={6}>
                            <Stack spacing={1}>
                                <Button variant="contained" color="facebook"
                                        sx={{color: "#fff", justifyContent: "start"}}
                                        onClick={() => {
                                            onLogin({provider: CognitoHostedUIIdentityProvider.Facebook});
                                        }}>
                                    <Iconify icon={"eva:facebook-fill"} sx={{mr: 2}}/>

                                    Login using Facebook
                                </Button>

                                <Button variant="contained" color="google"
                                        sx={{color: "#fff", justifyContent: "start"}}
                                        onClick={() => {
                                            onLogin({provider: CognitoHostedUIIdentityProvider.Google});
                                        }}>
                                    <Iconify icon={"akar-icons:google-fill"} sx={{mr: 2}}/>

                                    Login using Google
                                </Button>

                                {/*<Button variant="contained" color="apple" sx={{justifyContent: "start"}}*/}
                                {/*        onClick={() => {*/}
                                {/*            onLogin({provider: CognitoHostedUIIdentityProvider.Apple})*/}
                                {/*                .then(() => console.log("Logged in"))*/}
                                {/*                .catch(console.error);*/}
                                {/*        }}>*/}
                                {/*    <Iconify icon={"ant-design:apple-filled"} sx={{mr: 2}}/>*/}

                                {/*    Login using Apple*/}
                                {/*</Button>*/}

                                <Button variant="contained" color="amazon" sx={{justifyContent: "start"}}
                                        onClick={() => {
                                            onLogin({provider: CognitoHostedUIIdentityProvider.Amazon});
                                        }}>
                                    <Iconify icon={"bxl:amazon"} sx={{mr: 2}}/>

                                    Login using Amazon
                                </Button>
                            </Stack>

                            <LoginForm/>
                        </Stack>

                        {!smUp && (
                            <Typography variant="body2" align="center" sx={{mt: 3}}>
                                Don't have an account?{" "}
                                <Link variant="subtitle2" component={RouterLink} to="/register">
                                    Get started
                                </Link>
                            </Typography>
                        )}
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
};

const RootStyle = styled("div")(({theme}) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
    height: "100%"
}));

const HeaderStyle = styled("header")(({theme}) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    padding: theme.spacing(3),
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
        alignItems: "flex-start",
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({theme}) => ({
    width: "100%",
    maxWidth: 464,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(2, 0, 2, 2),
    backgroundColor: theme.palette.primary.dark,
    color: "#fff"
}));

const ContentStyle = styled("div")(({theme}) => ({
    maxWidth: 480 + 260 + 100,
    margin: "auto",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(12, 0),
}));
