import React from "react";
import {SaveButton, SimpleForm, Toolbar, useDataProvider} from "react-admin";
import {useMutation} from "react-query";
import {Card, CardContent, Container, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import dayjs from "dayjs";
import {TabbedInput} from "../input/TabbedInput";
import {TabbedEditorInput} from "../input/TabbedEditorInput";

export const NatalHoroscopeInfoPage: React.FC = (props) => {
    const {control, register, handleSubmit, watch, formState: { errors } } = useForm();

    const dataProvider = useDataProvider();
    const {mutate, isLoading, data} = useMutation((params: any) => dataProvider.calculateNatal(params));

    const onSubmit = (data: any) => {
        const params = {
            "dateTime": dayjs(data.datum).toISOString(),
            "timezone": data.tijdzone,
            "latitude": data.latitude,
            "longitude": data.longitude
        };

        mutate(params);
    };

    return (
        <Container>
            <Typography gutterBottom variant="h4">
                Geboortehoroscoop - Algemeen
            </Typography>

            <Card sx={{marginTop: 4, marginBottom: 4}}>
                <CardContent>
                    <div>
                        <SimpleForm onSubmit={onSubmit} toolbar={
                            <Toolbar>
                                <SaveButton label="Save"/>
                            </Toolbar>
                        }>
                            <TabbedInput name="vertalingen.${locale}.naam"/>
                            <TabbedEditorInput name="vertalingen.${locale}.beschrijving"/>
                        </SimpleForm>
                    </div>
                    {/*<div>*/}
                    {/*    <form onSubmit={handleSubmit(onSubmit)}>*/}
                    {/*        <TextField label="Intro" defaultValue="50.855103" {...register("latitude")}/><br/><br/>*/}

                    {/*        <TextField label="Beschrijving" defaultValue="4.305378" {...register("longitude")}/><br/><br/>*/}

                    {/*        <Button type="submit">Bewaren</Button>*/}
                    {/*    </form>*/}
                    {/*</div>*/}
                </CardContent>
            </Card>

        </Container>
    );
};
