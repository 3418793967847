// icons
import {Icon, IconifyIcon, IconProps} from '@iconify/react';
// @mui
import {Box} from '@mui/material';
import React from "react";

// ----------------------------------------------------------------------

export interface IconifyProps extends IconProps {
  icon: IconifyIcon | string;
  sx?: object;
}

const Iconify: React.FC<IconifyProps> = ({ icon, sx, ...other }: any) => {
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
};

export default Iconify;
