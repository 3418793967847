import React from "react";
import {styled} from "@mui/material/styles";
import {Box, Button, Card, Container, Link, Stack, Typography} from "@mui/material";
import useResponsive from "../theme/hooks/useResponsive";
import Page from "../theme/components/Page";
import Logo from "../theme/components/Logo";
import {NavLink as RouterLink} from "react-router-dom";
import {RegisterForm} from "./components/RegisterForm";
import Iconify from "../theme/components/Iconify";
import {Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {Images} from "../../static/images/Images";
import Image from "../theme/components/Image";

export const RegisterPage: React.FC = (props) => {
    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');

    return (
        <Page title="Register">
            <RootStyle>
                <HeaderStyle>
                    <Logo/>
                    {smUp && (
                        <Typography variant="body2" sx={{mt: {md: -2}}}>
                            Already have an account?{' '}
                            <Link variant="subtitle2" component={RouterLink} to="/login">
                                Login
                            </Link>
                        </Typography>
                    )}
                </HeaderStyle>

                {mdUp && (
                    <SectionStyle>
                        <Typography variant="h3" sx={{px: 5, mt: 10, mb: 5}}>
                            We deliver blazing fast astrology calculations with the very best interpretational texts.
                        </Typography>

                        <Image
                            alt="register"
                            src={Images.registerIllustration}
                        />
                    </SectionStyle>
                )}

                <Container>
                    <ContentStyle>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box sx={{flexGrow: 1}}>
                                <Typography variant="h4" gutterBottom>
                                    Get started with a free trial.
                                </Typography>
                                <Typography sx={{color: 'text.secondary'}}>No credit card needed. No
                                    commitment.</Typography>
                            </Box>
                        </Box>

                        <Box sx={{display: "flex", height: "100%", justifyContent: "stretch"}}>
                            <RegisterSocial>
                                <Stack spacing={1}>
                                    <Button variant="contained" color="facebook"
                                            sx={{color: "#fff", justifyContent: "start"}}
                                            onClick={() => {
                                                Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook})
                                                    .then(() => console.log("Logged in"))
                                                    .catch(console.error);
                                            }}>
                                        <Iconify icon={"eva:facebook-fill"} sx={{mr: 2}}/>

                                        Sign up with Facebook
                                    </Button>

                                    <Button variant="contained" color="google"
                                            sx={{color: "#fff", justifyContent: "start"}}
                                            onClick={() => {
                                                Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})
                                                    .then(() => console.log("Logged in"))
                                                    .catch(console.error);
                                            }}>
                                        <Iconify icon={"akar-icons:google-fill"} sx={{mr: 2}}/>

                                        Sign up with Google
                                    </Button>

                                    {/*<Button variant="contained" color="apple" sx={{justifyContent: "start"}}*/}
                                    {/*        onClick={() => {*/}
                                    {/*            onLogin({provider: CognitoHostedUIIdentityProvider.Apple})*/}
                                    {/*                .then(() => console.log("Logged in"))*/}
                                    {/*                .catch(console.error);*/}
                                    {/*        }}>*/}
                                    {/*    <Iconify icon={"ant-design:apple-filled"} sx={{mr: 2}}/>*/}

                                    {/*    Sign up with Apple*/}
                                    {/*</Button>*/}

                                    <Button variant="contained" color="amazon" sx={{justifyContent: "start"}}
                                            onClick={() => {
                                                Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Amazon})
                                                    .then(() => console.log("Logged in"))
                                                    .catch(console.error);
                                            }}>
                                        <Iconify icon={"bxl:amazon"} sx={{mr: 2}}/>

                                        Sign up with Amazon
                                    </Button>
                                </Stack>
                            </RegisterSocial>

                            <RegisterOrStyle>
                                <span>
                                    or
                                </span>
                            </RegisterOrStyle>

                            <RegisterEmailStyle>
                                <Typography variant="h5" gutterBottom>Sign up using an email address</Typography>

                                <RegisterForm/>

                                <Typography variant="body2" align="center" sx={{color: 'text.secondary', mt: 3}}>
                                    By registering, I agree to
                                    <Link underline="always" color="text.primary" href="https://api.bloom.be/users_agreement.pdf">
                                        Terms of Service
                                    </Link>
                                    {" "}and{" "}
                                    <Link underline="always" color="text.primary" href="https://api.bloom.be/policy.html">
                                        Privacy Policy
                                    </Link>
                                    .
                                </Typography>

                                {!smUp && (
                                    <Typography variant="body2" sx={{mt: 3, textAlign: 'center'}}>
                                        Already have an account?{' '}
                                        <Link variant="subtitle2" to="/login" component={RouterLink}>
                                            Login
                                        </Link>
                                    </Typography>
                                )}
                            </RegisterEmailStyle>
                        </Box>
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
};

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({theme}) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({theme}) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
    backgroundColor: theme.palette.primary.dark,
    color: "#fff"
}));

const ContentStyle = styled("div")(({theme}) => ({
    maxWidth: 480 + 260 + 100,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
}));

const RegisterOrStyle = styled("div")(() => ({
    minHeight: "100%",
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",

    span: {
        backgroundColor: "white",
        padding: 4,
        zIndex: 100,
    },

    "&:before": {
        content: '""',
        position: "absolute",

        width: 1,
        top: "20%",
        bottom: "20%",
        backgroundColor: "#bababa"
    }
}));

const RegisterSocial = styled("div")(({theme}) => ({
    maxWidth: 260,
    margin: "auto",
    padding: theme.spacing(12, 0)
}));

const RegisterEmailStyle = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));
