import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import debounce from 'lodash/debounce';
import {useDataProvider} from "react-admin";

interface PlaceType {
    description: string;
    lat: number;
    lng: number;
    timeZone: string;
}

export interface AddressSearchInputProps {
    onSelect: (location?: PlaceType) => any;
}

export const AddressSearchInput: React.FC<AddressSearchInputProps> = (props) => {
    const {onSelect} = props;

    const dataProvider = useDataProvider();

    const [value, setValue] = React.useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

    const fetch = React.useMemo(
        () =>
            debounce(
                async (
                    request: { input: string },
                    callback: (results?: readonly PlaceType[]) => void,
                ) => {
                    const {data = []} = await dataProvider.locationSearch(request.input);

                    callback(data.map((item: any) => ({
                        description: item.displayName,
                        lat: item.location.latitude,
                        lng: item.location.longitude,
                        timeZone: item.timeZone
                    })))
                },
                600
            ),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({input: inputValue}, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event: any, newValue: PlaceType | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);

                onSelect(newValue || undefined);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} InputLabelProps={{shrink: true}} label="Search for an address" fullWidth/>
            )}
            renderOption={(props, option) => {
                const matches = match(option.description, inputValue, {findAllOccurrences: true});
                const parts = parse(option.description, matches);

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Box
                                    component={LocationOnIcon}
                                    sx={{color: 'text.secondary', mr: 2}}
                                />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                    {part.text}
                  </span>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {/*{option.description}*/}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
