import React, {ReactNode} from "react";
import {useInput} from "react-admin";
import {Editor} from "./Editor";
import {InputLabel} from "@mui/material";

export interface TextEditorInputProps {
    source: string;
    label?: ReactNode;
    disabled?: boolean;
}

export const TextEditorInput: React.FC<TextEditorInputProps> = (props) => {
    const {source, label, disabled} = props;

    const input = useInput({source});

    const {isSubmitted} = input.formState;
    const {invalid, isTouched, error} = input.fieldState;

    return (
        <div>
            <InputLabel>{label}</InputLabel>
            <Editor
                {...input.field}
                // error={(isTouched || isSubmitted) && invalid}
                // helperText={(isTouched || isSubmitted) && invalid ? error : ''}
            />
        </div>
    );
};
