import React from "react";
import {Title, useDataProvider} from "react-admin";
import {useMutation} from "react-query";
import {Button, Card, CardContent, CardHeader, Container, Grid, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {PlanetInSignCard} from "../components/horoscope/PlanetInSignCard";
import {HouseCard} from "../components/horoscope/HouseCard";
import {AspectCard} from "../components/horoscope/AspectCard";
import {AddressSearchInput} from "../components/AddressSearchInput";
import Image from "../theme/components/Image";
import {DateTimePickerInput} from "../input/DateTimePickerInput";
import {PlanetInHouseCard} from "../components/horoscope/PlanetInHouseCard";

interface FormValues {
    datum?: string;
    tijdzone?: string;
    latitude?: number;
    longitude?: number;
}

export const NatalTryItOutPage: React.FC = (props) => {
    const {control, register, handleSubmit, setValue, watch, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            datum: "",
            tijdzone: undefined,
            latitude: undefined,
            longitude: undefined
        }
    });

    const dataProvider = useDataProvider();

    const {mutate, isLoading, data} = useMutation((params: any) => dataProvider.calculateNatal(params));

    const onSubmit = (data: FormValues) => {
        const params = {
            "dateTime": dayjs(data.datum!).set("seconds", 0).set("ms", 0).toISOString(),
            "timezone": data.tijdzone!,
            "latitude": data.latitude!,
            "longitude": data.longitude!
        };

        mutate(params);
    };

    const renderPlanetInSign = (planet: any) => {
        return (
            <Grid item xs={12}>
                <PlanetInSignCard planet={planet}/>
            </Grid>
        );
    };

    const renderPlanetInHouse = (planet: any) => {
        return (
            <Grid item xs={12}>
                <PlanetInHouseCard planet={planet}/>
            </Grid>
        );
    };

    const renderHouse = (house: any) => {
        return (
            <Grid item xs={12}>
                <HouseCard house={house}/>
            </Grid>
        );
    };

    const renderAspect = (aspect: any) => {
        return (
            <Grid item xs={12}>
                <AspectCard aspect={aspect}/>
            </Grid>
        );
    };

    return (
        <Container>
            <Card sx={{marginTop: 4, marginBottom: 4}}>
                <Title title="test"/>
                <CardHeader title="Calculate Natal Horoscope"/>

                <CardContent>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <DateTimePickerInput
                                name="datum"
                                rules={{ required: true }}
                                control={control}/>
                            <br/><br/>

                            <AddressSearchInput onSelect={(place) => {
                                if (place) {
                                    setValue("latitude", place.lat);
                                    setValue("longitude", place.lng);
                                    setValue("tijdzone", place.timeZone);
                                } else {
                                    setValue("latitude", undefined);
                                    setValue("longitude", undefined);
                                    setValue("tijdzone", undefined);
                                }
                            }}/>
                            <br/>

                            <Grid container spacing={2}>
                                <Grid item>
                                    <TextField label="Latitude" type="number" InputLabelProps={{shrink: true}} inputProps={{step: "any"}} {...register("latitude")}/>
                                </Grid>

                                <Grid item>
                                    <TextField label="Longitude" type="number" InputLabelProps={{shrink: true}} inputProps={{step: "any"}} {...register("longitude")}/>
                                </Grid>

                                <Grid item>
                                    <TextField label="Timezone" InputLabelProps={{shrink: true}} defaultValue="Europe/Brussels" {...register("tijdzone")}/>
                                </Grid>
                            </Grid>

                            <Button type="submit">Calculate</Button>
                        </form>
                    </div>
                </CardContent>
            </Card>

            <h2>Wheel chart</h2>
            <Grid container>
                <Grid item xs={12}>
                    {(data as any)?.data?.chartUrl && (
                        <Image src={(data as any)?.data?.chartUrl}/>
                    )}
                </Grid>
            </Grid>

            <h2>Planets and advanced points in signs</h2>
            <Grid container spacing={2}>
                {(data as any)?.data?.planets?.map?.((item: any) => renderPlanetInSign(item))}
            </Grid>

            <h2>Planets and advanced points in Houses</h2>
            <Grid container spacing={2}>
                {(data as any)?.data?.planets?.map?.((item: any) => renderPlanetInHouse(item))}
            </Grid>

            <h2>Houses</h2>
            <Grid container spacing={2}>
                {(data as any)?.data?.houseCusps?.map?.((item: any) => renderHouse(item))}
            </Grid>

            <h2>Aspects</h2>
            <Grid container spacing={2}>
                {(data as any)?.data?.aspects?.concat()?.sort((a: any, b: any) => Object.keys(b.text || {}).length - Object.keys(a.text || {}).length)?.map?.((item: any) => renderAspect(item))}
            </Grid>
        </Container>
    );
};
