// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name: string) => <Iconify icon={name} width={22} height={22}/>;

const navConfig = [
    {
        subheader: "General",
        items: [
            {
                title: 'Dashboard',
                path: '/',
                icon: getIcon('carbon:dashboard'),
            },
            {
                title: 'Documentation',
                path: window.__RUNTIME_CONFIG__.DOCS_URL,
                icon: getIcon('bi:file-text'),
            },
            {
                title: "API Keys",
                path: "/api-keys",
                icon: getIcon("carbon:dashboard")
            }
        ]
    },
    {
        subheader: "Admin",
        permissions: ["ADMIN"],
        items: [
            {
                title: "Users",
                path: "/users",
                icon: getIcon("bxs:user-detail")
            }
        ]
    },
    {
        subheader: "Management",
        items: [
            {
                title: "Basic translations",
                icon: getIcon('akar-icons:settings-horizontal'),
                children: [
                    {
                        title: 'Planets',
                        path: '/planets',
                        icon: getIcon('iconoir:planet'),
                    },
                    {
                        title: 'Zodiac signs',
                        path: '/zodiac-signs',
                        icon: getIcon('mdi:zodiac-cancer'),
                    },
                    {
                        title: 'Chinese zodiac signs',
                        path: '/chinese-zodiac-signs',
                        icon: getIcon('uil:letter-chinese-a'),
                    },
                    {
                        title: 'Houses',
                        path: '/houses',
                        icon: getIcon('ep:house'),
                    },
                    {
                        title: 'Aspects',
                        path: '/aspects',
                        icon: getIcon('radix-icons:angle'),
                    },
                    {
                        title: 'Elements',
                        path: '/elements',
                        icon: getIcon('bx:leaf'),
                    },
                    {
                        title: 'Element Strength',
                        path: '/element-strength',
                        icon: getIcon('ic:baseline-air'),
                    },
                    {
                        title: 'Zodiac Points',
                        path: '/zodiac-points',
                        icon: getIcon('mdi:zodiac-libra'),
                    }
                ]
            },
            {
                title: 'Compatibility',
                icon: getIcon('akar-icons:people-multiple'),
                children: [
                    {title: 'Try it', path: "/compatibility-try-it-out", icon: getIcon('carbon:play')},
                    // {title: 'General', path: "/natal-info", icon: getIcon('akar-icons:settings-horizontal')},
                    {title: 'Texts', path: "/horoscope-compatibility-texts", icon: getIcon('akar-icons:circle')}
                ],
            },
            {
                title: 'Natal horoscope',
                icon: getIcon('fe:birthday-cake'),
                children: [
                    {title: 'Try it', path: "/natal-try-it-out", icon: getIcon('carbon:play')},
                    // {title: 'General', path: "/natal-info", icon: getIcon('akar-icons:settings-horizontal')},
                    {title: 'Basic version', path: "/natal-texts-basic", icon: getIcon('akar-icons:circle')},
                    {title: 'Planet in sign', path: "/natal-texts-planet-in-sign", icon: getIcon('iconoir:planet')},
                    {title: 'Planet in house', path: "/natal-texts-planet-in-house", icon: getIcon('iconoir:planet')},
                    {
                        title: 'Sign on house cusp',
                        path: "/natal-texts-sign-on-house-cusp",
                        icon: getIcon('eva:pie-chart-2-fill')
                    },
                    {title: 'Aspects', path: "/natal-texts-aspects", icon: getIcon('radix-icons:angle')},
                ],
            },
            {
                title: 'Transit horoscope',
                icon: getIcon('iconoir:planet'),
                children: [
                    {title: 'Try it', path: "/transit-try-it-out", icon: getIcon('carbon:play')},
                    // {title: 'General', path: "/transit-general", icon: getIcon('akar-icons:settings-horizontal')},
                    {title: 'Planet in house', path: "/transit-texts-planet-in-house", icon: getIcon('iconoir:planet')},
                    {title: 'Aspects', path: "/transit-texts-aspects", icon: getIcon('radix-icons:angle')},
                ],
            },
            {
                title: 'Solar Return',
                icon: getIcon('ph:sun-dim-fill'),
                children: [
                    {title: 'Try it', path: "/solarreturn-try-it-out", icon: getIcon('carbon:play')},
                    // {title: 'General', path: "/solarreturn-general", icon: getIcon('akar-icons:settings-horizontal')},
                    {title: 'Planet in house', path: "/solarreturn-point-in-house", icon: getIcon('iconoir:planet')},
                    {title: 'Point in zodiac sign', path: "/solarreturn-point-in-sign", icon: getIcon('iconoir:planet')},
                ],
            },
            {
                title: 'Lunar Return',
                icon: getIcon('ph:moon-fill'),
                children: [
                    {title: 'Try it', path: "/lunarreturn-try-it-out", icon: getIcon('carbon:play')},
                    // {title: 'General', path: "/lunarreturn-general", icon: getIcon('akar-icons:settings-horizontal')},
                    {title: 'Planet in house', path: "/lunarreturn-point-in-house", icon: getIcon('iconoir:planet')},
                    {title: 'Point in zodiac sign', path: "/lunarreturn-point-in-sign", icon: getIcon('iconoir:planet')},
                ],
            },
            {
                title: 'Synastry',
                icon: getIcon('iconoir:planet'),
                children: [
                    {title: 'Try it', path: "/synastry-try-it-out", icon: getIcon('carbon:play')},
                    // {title: 'General', path: "/synastry-general", icon: getIcon('akar-icons:settings-horizontal')},
                    {title: 'Aspects', path: "/synastry-texts-aspects", icon: getIcon('radix-icons:angle')}
                ],
            },
            {
                title: 'Composite horoscope',
                icon: getIcon('iconoir:planet'),
                children: [
                    {title: 'Try it', path: "/composite-try-it-out", icon: getIcon('carbon:play')},
                    // {title: 'General', path: "/composite-general", icon: getIcon('akar-icons:settings-horizontal')},
                    {title: 'Planet in house', path: "/composite-texts-planet-in-house", icon: getIcon('iconoir:planet')},
                    {title: 'Aspects', path: "/composite-texts-aspects", icon: getIcon('radix-icons:angle')},
                ],
            },
            // {
            //     title: 'Forecast',
            //     icon: getIcon('wpf:future'),
            //     children: [
            //         // {title: 'Try it', path: "/forecast-try-it-out", icon: getIcon('carbon:play')},
            //         {title: 'Weekly', path: "/forecast-weekly", icon: getIcon('bi:calendar-week')},
            //         {title: 'Monthly', path: "/forecast-monthly", icon: getIcon('bi:calendar-month')},
            //         {title: 'Yearly', path: "/forecast-yearly", icon: getIcon('bi:calendar-date')},
            //         {title: 'Yearly (Ascendant)', path: "/forecast-yearly-ascendant", icon: getIcon('bi:calendar-date')},
            //     ],
            // },
            {
                title: 'Chinese Horoscope',
                icon: getIcon('mdi:ideogram-chinese-japanese-korean-variant'),
                children: [
                    {title: 'Try it', path: "/chinese-horoscope-try-it-out", icon: getIcon('carbon:play')},
                    {title: 'Basic', path: "/chinese-horoscope-basic", icon: getIcon('akar-icons:circle')},
                    {title: 'Yearly', path: "/chinese-horoscope-yearly", icon: getIcon('bi:calendar-date')}
                ]
            }
        ]
    }
];

export default navConfig;
