import React, {useEffect} from "react";
import {styled} from "@mui/material/styles";
import {Box, Container, LinearProgress, Typography} from "@mui/material";
import Page from "../theme/components/Page";
import {useDataProvider, useGetIdentity} from "react-admin";
import {useMutation} from "react-query";
import LogoOnlyLayout from "../theme/layouts/LogoOnlyLayout";
import {useNavigate} from "react-router";

export const SetupPage: React.FC = (props) => {
    const navigate = useNavigate();

    const {identity, isLoading: isIdentityLoading} = useGetIdentity();

    const dataProvider = useDataProvider();
    const {mutate, isLoading, data} = useMutation((params: any) => dataProvider.accountSetup(params));

    useEffect(() => {
        if (isIdentityLoading) {
            return;
        }

        if (identity?.id === "not-authenticated") {
            mutate({}, {
                onSuccess: () => {
                    setTimeout(() => {
                        navigate("/");
                    }, 3_000);
                }
            });
        } else {
            setTimeout(() => {
                navigate("/");
            }, 3_000);
        }
    }, [identity, isIdentityLoading]);

    return (
        <Page title="Account Setup">
            <RootStyle>
                <LogoOnlyLayout/>

                <Container>
                    <Box sx={{maxWidth: 480, mx: "auto"}}>
                        <Typography variant="h3" paragraph>
                            We are setting up your account...
                        </Typography>
                        <Typography sx={{color: "text.secondary", mb: 5}}>
                            Hold on tight while we setup a new account for you. You will be redirected shortly.
                        </Typography>

                        <LinearProgress/>
                    </Box>
                </Container>
            </RootStyle>
        </Page>
    );
};

const RootStyle = styled("div")(({theme}) => ({
    display: "flex",
    minHeight: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(12, 0),
}));
