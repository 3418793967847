import React from "react";
import {useDataProvider} from "react-admin";
import {useMutation} from "react-query";
import {Button, Card, CardContent, CardHeader, Container, Grid, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {PlanetInSignCard} from "../components/horoscope/PlanetInSignCard";
import {HouseCard} from "../components/horoscope/HouseCard";
import {AspectCard} from "../components/horoscope/AspectCard";
import {AddressSearchInput} from "../components/AddressSearchInput";
import Image from "../theme/components/Image";
import Page from "../theme/components/Page";
import {DateTimePickerInput} from "../input/DateTimePickerInput";

interface FormValues {
    natalDate?: string;
    natalTimezone?: string;
    natalLatitude?: number;
    natalLongitude?: number;

    eventDate?: string;
    eventTimezone?: string;
    eventLatitude?: number;
    eventLongitude?: number;
}

export const TransitTryItOutPage: React.FC = (props) => {
    const {control, register, handleSubmit, setValue, watch, formState: {errors}} = useForm<FormValues>({
        defaultValues: {
            natalDate: "",
            eventDate: ""
        }
    });

    const dataProvider = useDataProvider();

    const {mutate, isLoading, data} = useMutation((params: any) => dataProvider.calculateTransit(params));

    const onSubmit = (data: FormValues) => {
        const params = {
            "natalDateTime": dayjs(data.natalDate!).set("seconds", 0).set("ms", 0).toISOString(),
            "natalTimezone": data.natalTimezone!,
            "natalLatitude": data.natalLatitude!,
            "natalLongitude": data.natalLongitude!,

            "eventDateTime": dayjs(data.eventDate!).set("seconds", 0).set("ms", 0).toISOString(),
            "eventTimezone": data.eventTimezone!,
            "eventLatitude": data.eventLatitude!,
            "eventLongitude": data.eventLongitude!
        };

        mutate(params);
    };

    const renderPlanet = (planet: any) => {
        return (
            <Grid item xs={12}>
                <PlanetInSignCard planet={planet}/>
            </Grid>
        );
    };

    const renderHouse = (house: any) => {
        return (
            <Grid item xs={12}>
                <HouseCard house={house}/>
            </Grid>
        );
    };

    const renderAspect = (aspect: any) => {
        return (
            <Grid item xs={12}>
                <AspectCard aspect={aspect}/>
            </Grid>
        );
    };

    return (
        <Page title="Calculate Transit Horoscope">
            <Container>
                <h1>Calculate Transit Horoscope</h1>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Card sx={{marginTop: 4, marginBottom: 4}}>
                        <CardHeader title="Person Birth Details"/>

                        <CardContent>
                            <div>

                                <DateTimePickerInput
                                    name="natalDate"
                                    rules={{ required: true }}
                                    control={control}/>
                                <br/><br/>

                                <AddressSearchInput onSelect={(place) => {
                                    if (place) {
                                        setValue("natalLatitude", place.lat);
                                        setValue("natalLongitude", place.lng);
                                        setValue("natalTimezone", place.timeZone);
                                    } else {
                                        setValue("natalLatitude", undefined);
                                        setValue("natalLongitude", undefined);
                                        setValue("natalTimezone", undefined);
                                    }
                                }}/>
                                <br/>

                                <Grid container spacing={2}>
                                    <Grid item>
                                        <TextField label="Latitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("natalLatitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Longitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("natalLongitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Timezone" InputLabelProps={{shrink: true}}
                                                   defaultValue="Europe/Brussels" {...register("natalTimezone")}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    </Card>

                    <Card sx={{marginTop: 4, marginBottom: 4}}>
                        <CardHeader title="Event Details"/>

                        <CardContent>
                            <div>

                                <DateTimePickerInput
                                    name="eventDate"
                                    rules={{ required: true }}
                                    control={control}/>
                                <br/><br/>

                                <AddressSearchInput onSelect={(place) => {
                                    if (place) {
                                        setValue("eventLatitude", place.lat);
                                        setValue("eventLongitude", place.lng);
                                        setValue("eventTimezone", place.timeZone);
                                    } else {
                                        setValue("eventLatitude", undefined);
                                        setValue("eventLongitude", undefined);
                                        setValue("eventTimezone", undefined);
                                    }
                                }}/>
                                <br/>

                                <Grid container spacing={2}>
                                    <Grid item>
                                        <TextField label="Latitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("eventLatitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Longitude" type="number" InputLabelProps={{shrink: true}}
                                                   inputProps={{step: "any"}} {...register("eventLongitude")}/>
                                    </Grid>

                                    <Grid item>
                                        <TextField label="Timezone" InputLabelProps={{shrink: true}}
                                                   defaultValue="Europe/Brussels" {...register("eventTimezone")}/>
                                    </Grid>
                                </Grid>

                                <Button type="submit">Calculate</Button>
                            </div>
                        </CardContent>
                    </Card>
                </form>

                <h2>Wheel chart</h2>
                <Grid container>
                    <Grid item xs={12}>
                        {(data as any)?.data?.chartUrl && (
                            <Image src={(data as any)?.data?.chartUrl}/>
                        )}
                    </Grid>
                </Grid>

                <h2>Planets</h2>
                <Grid container spacing={2}>
                    {(data as any)?.data?.planets?.map?.((item: any) => renderPlanet(item))}
                </Grid>

                <h2>Aspects</h2>
                <Grid container spacing={2}>
                    {(data as any)?.data?.aspects?.concat()?.sort((a: any, b: any) => Object.keys(b.text || {}).length - Object.keys(a.text || {}).length)?.map?.((item: any) => renderAspect(item))}
                </Grid>
            </Container>
        </Page>
    );
};
