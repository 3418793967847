import React from "react";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import {DecimalDegree} from "../DecimalDegree";
import {Config} from "../../../Config";
import {Markdown} from "../Markdown";

export interface AspectCardProps {
    aspect: any;
}

export const AspectCard: React.FC<AspectCardProps> = (props) => {
    const {
        planet1,
        planet2,
        distance,
        zodiacSign,
        aspect,
        text
    } = props.aspect;

    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>

                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            <DecimalDegree decimalDegree={distance}/>
                        </Typography>
                    </Grid>
                </Grid>

                <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
                    {Config.labels.bodies[planet1]} {Config.labels.aspects[aspect]} {Config.labels.bodies[planet2]}
                </Typography>

                {/*<Typography sx={{ mb: 1.5 }} color="text.secondary">*/}
                {/*    */}
                {/*</Typography>*/}

                <Typography variant="body2">
                    {text?.[Config.defaultLanguage]?.intro}
                </Typography>
                <Typography variant="body2" dangerouslySetInnerHTML={{__html: text?.[Config.defaultLanguage]?.description}}/>
            </CardContent>

            {/*<CardActions>*/}
            {/*    <Button size="small">Learn More</Button>*/}
            {/*</CardActions>*/}
        </Card>
    );
};
