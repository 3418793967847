import PropTypes from 'prop-types';
// @mui
import {Box, Button, Card, CircularProgress, Grid, IconButton, Paper, Stack, Switch, Typography} from '@mui/material';
// components
import Image from '../../theme/components/Image';
import Iconify from '../../theme/components/Iconify';
import {useDataProvider, useGetIdentity} from "react-admin";
import {useMutation} from "react-query";
import {useNavigate} from "react-router";

// ----------------------------------------------------------------------

AccountBillingPaymentMethod.propTypes = {
    cards: PropTypes.array,
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onCancel: PropTypes.func,
};

export default function AccountBillingPaymentMethod({cards, isOpen, onOpen, onCancel}) {
    const {identity} = useGetIdentity();
    const navigate = useNavigate();

    const dataProvider = useDataProvider();
    const {
        mutate: setupStripe,
        isLoading: setupStripeLoading
    } = useMutation((params) => dataProvider.accountSetupStripe(params));
    const {
        mutate: billingPortalStripe,
        isLoading: billingPortalStripeLoading
    } = useMutation((params) => dataProvider.accountStripeBillingPortal(params));

    const onStripeSwitchChange = (event) => {
        if (event.target.checked) {
            setupStripe();
        }
    };

    const onStripeConfigureClick = () => {
        if (!identity["activePaymentMethods"]?.includes("STRIPE")) {
            setupStripe(undefined, {
                onSuccess: () => {
                    billingPortalStripe(void 0, {
                        onSuccess: ({data}) => {
                            window.open(data.url);
                        }
                    });
                }
            });
        } else {
            billingPortalStripe(void 0, {
                onSuccess: ({data}) => {
                    window.open(data.url);
                }
            });
        }
    };

    return (
        <Card sx={{p: 3}}>
            <Typography variant="overline" sx={{mb: 3, display: 'block', color: 'text.secondary'}}>
                Payment Method
            </Typography>

            <Stack spacing={2} direction={{xs: 'column', md: 'row'}}>
                <Paper
                    sx={{
                        p: 3,
                        width: 1,
                        position: 'relative',
                        border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
                    }}
                >
                    <Stack direction="row">
                        <Image
                            alt="icon"
                            src='https://minimal-assets-api.vercel.app/assets/icons/ic_mastercard.svg'
                            sx={{mb: 1, maxWidth: 36}}
                        />
                        <Image
                            alt="icon"
                            src='https://minimal-assets-api.vercel.app/assets/icons/ic_visa.svg'
                            sx={{mb: 1, maxWidth: 36}}
                        />
                    </Stack>
                    <Grid container alignItems="center" justifyContent="space-between" direction="row">
                        <Grid item>
                            <Typography variant="subtitle2">Stripe</Typography>
                        </Grid>
                        <Grid item>
                            <Button size="small" onClick={onStripeConfigureClick}>Configure</Button>
                        </Grid>
                    </Grid>
                    <Box sx={{top: 8, right: 8, position: "absolute"}}>
                        {setupStripeLoading && <CircularProgress/>}
                        {identity && <Switch defaultChecked={identity["activePaymentMethods"]?.includes("STRIPE")} onChange={onStripeSwitchChange}/>}
                    </Box>
                    {/*<IconButton*/}
                    {/*    sx={{*/}
                    {/*        top: 8,*/}
                    {/*        right: 8,*/}
                    {/*        position: 'absolute',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Iconify icon={'eva:more-vertical-fill'} width={20} height={20}/>*/}
                    {/*</IconButton>*/}
                </Paper>

                {/*<Paper*/}
                {/*    sx={{*/}
                {/*        p: 3,*/}
                {/*        width: 1,*/}
                {/*        position: 'relative',*/}
                {/*        border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Stack direction="row">*/}
                {/*        <Image*/}
                {/*            alt="icon"*/}
                {/*            src='https://minimal-assets-api.vercel.app/assets/icons/ic_paypal.svg'*/}
                {/*            sx={{ mb: 1, maxWidth: 36 }}*/}
                {/*        />*/}
                {/*    </Stack>*/}
                {/*    <Grid container alignItems="center" justifyContent="space-between" direction="row">*/}
                {/*        <Grid item>*/}
                {/*            <Typography variant="subtitle2">PayPal</Typography>*/}
                {/*        </Grid>*/}
                {/*        <Grid item>*/}
                {/*            <Button size="small">Configure</Button>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*    <IconButton*/}
                {/*        sx={{*/}
                {/*            top: 8,*/}
                {/*            right: 8,*/}
                {/*            position: 'absolute',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />*/}
                {/*    </IconButton>*/}
                {/*</Paper>*/}
            </Stack>

            {/*<Box sx={{ mt: 3 }}>*/}
            {/*  <Button size="small" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={onOpen}>*/}
            {/*    Add payment method*/}
            {/*  </Button>*/}
            {/*</Box>*/}

            {/*<Collapse in={isOpen}>*/}
            {/*  <Box*/}
            {/*    sx={{*/}
            {/*      padding: 3,*/}
            {/*      marginTop: 3,*/}
            {/*      borderRadius: 1,*/}
            {/*      bgcolor: 'background.neutral',*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Stack spacing={3}>*/}
            {/*      <Typography variant="subtitle1">Add new card</Typography>*/}

            {/*      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>*/}
            {/*        <TextField fullWidth label="Name on card" />*/}

            {/*        <TextField fullWidth label="Card number" />*/}
            {/*      </Stack>*/}

            {/*      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>*/}
            {/*        <TextField fullWidth label="Expiration date" placeholder="MM/YY" />*/}

            {/*        <TextField fullWidth label="Cvv" />*/}
            {/*      </Stack>*/}

            {/*      <Stack direction="row" justifyContent="flex-end" spacing={1.5}>*/}
            {/*        <Button color="inherit" variant="outlined" onClick={onCancel}>*/}
            {/*          Cancel*/}
            {/*        </Button>*/}
            {/*        <LoadingButton type="submit" variant="contained" onClick={onCancel}>*/}
            {/*          Save Change*/}
            {/*        </LoadingButton>*/}
            {/*      </Stack>*/}
            {/*    </Stack>*/}
            {/*  </Box>*/}
            {/*</Collapse>*/}
        </Card>
    );
}
