import {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {alpha} from '@mui/material/styles';
import {Avatar, Box, Divider, IconButton, MenuItem, Stack, Typography} from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import {useGetIdentity, useLogout} from "react-admin";
import {Auth} from "aws-amplify";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Account',
        icon: 'eva:person-fill',
        linkTo: '/account',
    }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const anchorRef = useRef(null);

    const {identity} = useGetIdentity();
    const logout = useLogout();

    const [open, setOpen] = useState(null);

    const handleOpen = (event: any) => {
        setOpen(event.currentTarget);
    };

    const handleLogout = () => {
        logout();
        handleClose();
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    p: 1,
                    width: 40, height: 40,
                    ...(open ? {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    } : {}),
                }}
            >
                <Iconify icon="mdi:account"/>
                {/*<Avatar src={account.photoURL} alt="photoURL"/>*/}
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle2" noWrap>
                        {identity?.fullName}
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                        {identity?.email}
                    </Typography>
                </Box>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <Stack sx={{p: 1}}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <MenuItem onClick={handleLogout} sx={{m: 1}}>
                    Logout
                </MenuItem>
            </MenuPopover>
        </>
    );
}
