import React, {useState} from "react";
import {Alert, Button, IconButton, InputAdornment, Stack} from "@mui/material";
import RHFTextField from "../../components/form/RHFTextField";
import Iconify from "../../theme/components/Iconify";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {LoadingButton} from "@mui/lab";
import * as Yup from "yup";
import YupPassword from "yup-password";
import {Auth} from "aws-amplify";
import {useNavigate} from "react-router";
import {useNotify} from "react-admin";
YupPassword(Yup);

export interface RegisterFormValues {
    name: string;
    email: string;
    password: string;

    code: string;
}

/**
 * Contains at least 1 number
 * Contains at least 1 special character (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + =)
 * Contains at least 1 uppercase letter
 * Contains at least 1 lowercase letter
 */

export const RegisterForm: React.FC = (props) => {
    // const { login } = useAuth();
    //
    // const isMountedRef = useIsMountedRef();

    const navigate = useNavigate();
    const notify = useNotify();

    const [showPassword, setShowPassword] = useState(false);
    const [verify, setVerify] = useState(false);

    const LoginSchema = Yup.object().shape({
        firstName: Yup.string().required("First name required"),
        lastName: Yup.string().required("Last name required"),
        email: Yup.string().email("Email must be a valid email address").required("Email is required"),
        password: Yup.string().password()
    });

    const defaultValues: RegisterFormValues = {
        name: "",
        email: "",
        password: "",
        code: ""
    };

    const methods = useForm<RegisterFormValues & { afterSubmit?: string }>({
        resolver: yupResolver(LoginSchema),
        defaultValues
    });

    const {
        reset,
        setError,
        handleSubmit,
        getValues,
        formState: {errors, isSubmitting}
    } = methods;

    const values = getValues();

    const onSubmit = async (data: any) => {
        try {
            if (verify) {
                await Auth.confirmSignUp(`email:${data.email}`, data.code);

                notify("Your account has been verified. You can now log in.");
                navigate("/login");
            } else {
                const result = await Auth.signUp({
                    username: `email:${data.email}`,
                    password: data.password,
                    attributes: {
                        email: data.email,
                        name: data.firstName + " " + data.lastName
                    }
                });

                setVerify(true);
                notify("You have been registered. Please enter the verification code that you been send to your e-mail address.");
            }
        } catch (error: any) {
            if (error.code === "UsernameExistsException") {
                setError("email", {message: "E-mail already in use"});
            }

            console.error(error);
            // reset();
            // if (isMountedRef.current) {
            //     setError("afterSubmit", error);
            // }
        }
    };

    const onResend = async () => {
        await Auth.resendSignUp(`email:${values.email}`);
        notify("Verification code resent.");
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

                    {!verify && (
                        <>
                            <Stack direction={{xs: "column", sm: "row"}} spacing={2}>
                                <RHFTextField name="firstName" label="First name"/>
                                <RHFTextField name="lastName" label="Last name"/>
                            </Stack>

                            <RHFTextField name="email" label="Email address"/>

                            <RHFTextField
                                name="password"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                                <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </>
                    )}

                    {verify && (
                        <>
                            <p>
                                A verification code has been sent to your e-mail address. Please enter it below.
                            </p>
                            <RHFTextField name="code" label="Verification code"/>
                        </>
                    )}

                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                        {verify ? "Verify" : "Register"}
                    </LoadingButton>

                    {verify && <Button fullWidth size="large" type="button" variant="contained" onClick={onResend}>
                        Resend verification code
                    </Button>}
                </Stack>
            </form>
        </FormProvider>
    );
};
