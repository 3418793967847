import React, {ReactNode, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {TabPanel} from "@mui/lab";

const LOCALES = [
    {label: "English", value: "en"},
    // {label: "Dutch", value: "nl"}
];

export interface LanguageTabProps {
    children: (locale: string) => ReactNode;
}

export const LanguageTab: React.FC<LanguageTabProps> = (props) => {
    const {children} = props;

    const [selectedIndex, setSelectedIndex] = useState(0);
    const currentLocale = LOCALES[selectedIndex];

    return (
        <>
            <Tabs value={selectedIndex} onChange={(_, index) => setSelectedIndex(index)} sx={{mb: 3}}>
                {LOCALES.map(item => <Tab label={item.label}/>)}
            </Tabs>

            <div key={currentLocale.value}>
                {children(currentLocale.value)}
            </div>
        </>
    );
};
