import React, {ReactNode, useState} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {TabbedInputField} from "./TabbedInputField";
import {FormControl, InputLabel} from "@mui/material";

const TabPanel: React.FC<{ visible: boolean }> = (props) => {
    if (!props.visible) {
        return null;
    }

    return (
        <div>
            {props.children}
        </div>
    );
};

export interface TabbedInputProps {
    name: string;
    label?: ReactNode;
    disabled?: boolean;
}

export const TabbedInput: React.FC<TabbedInputProps> = (props) => {
    const {name, label, disabled} = props;

    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <>
            <Tabs value={selectedIndex} onChange={(_, index) => setSelectedIndex(index)} sx={{mb: 3}}>
                <Tab label="English"/>
                <Tab label="Dutch"/>
            </Tabs>

            <TabPanel visible={selectedIndex === 0}>
                <TabbedInputField name={name} locale="en" disabled={disabled} label={label}/>
            </TabPanel>
            <TabPanel visible={selectedIndex === 1}>
                <TabbedInputField name={name} locale="nl" disabled={disabled} label={label}/>
            </TabPanel>
        </>
    );
};
