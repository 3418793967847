import React from "react";
import {TextEditorInput} from "../input/TextEditorInput";

export interface TekstMetIntroFieldsProps {
    disabled?: boolean;
    locale: string;
}

export const TekstMetIntroFields: React.FC<TekstMetIntroFieldsProps> = (props) => {
    const {disabled, locale} = props;

    return (
        <>
            <TextEditorInput source={`translations.${locale}.intro`} disabled={disabled} label="Intro"/>
            <TextEditorInput source={`translations.${locale}.description`} disabled={disabled} label="Description"/>
        </>
    );
};
