import React from "react";
import {Title, useDataProvider, Form, ReferenceInput, SelectInput} from "react-admin";
import {useMutation} from "react-query";
import {Button, Card, CardContent, CardHeader, Container, Grid, TextField, Typography} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {PlanetInSignCard} from "../components/horoscope/PlanetInSignCard";
import {HouseCard} from "../components/horoscope/HouseCard";
import {AspectCard} from "../components/horoscope/AspectCard";
import {AddressSearchInput} from "../components/AddressSearchInput";
import Image from "../theme/components/Image";
import {Config} from "../../Config";
import {Markdown} from "../components/Markdown";

interface FormValues {
    zodiacSign1?: string;
    zodiacSign2?: string;
}

export const CompatibilityTryItOutPage: React.FC = (props) => {
    const {control, register, handleSubmit, setValue, watch, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
        }
    });

    const dataProvider = useDataProvider();

    const {mutate, isLoading, data} = useMutation((params: any) => dataProvider.calculateCompatibility(params));

    const onSubmit = (data: FormValues) => {
        const params = {
            zodiacSign1: data.zodiacSign1!,
            zodiacSign2: data.zodiacSign2!
        };

        mutate(params);
    };

    const text = (data as any)?.data?.text;

    return (
        <Container>
            <Card sx={{marginTop: 4, marginBottom: 4}}>
                <Title title="test"/>
                <CardHeader title="Horoscope Compatibility"/>

                <CardContent>
                    <div>
                        <Form onSubmit={onSubmit}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ReferenceInput source="zodiacSign1" reference="zodiac-signs">
                                        <SelectInput label="Zodiac sign 1" optionText={`translations.${Config.defaultLanguage}.name`} optionValue="urn"/>
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12}>
                                    <ReferenceInput source="zodiacSign2" reference="zodiac-signs">
                                        <SelectInput label="Zodiac sign 2" optionText={`translations.${Config.defaultLanguage}.name`} optionValue="urn"/>
                                    </ReferenceInput>
                                </Grid>
                            </Grid>

                            <Button type="submit">Calculate</Button>
                        </Form>
                    </div>
                </CardContent>
            </Card>

            <h2>Result</h2>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        {text?.[Config.defaultLanguage]?.intro}
                    </Typography>
                    <Typography variant="body2">
                        <Markdown>
                            {text?.[Config.defaultLanguage]?.description}
                        </Markdown>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};
