import React from "react";
import {
    CloneButton,
    Create,
    CreateButton,
    DeleteWithConfirmButton,
    Edit,
    List,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    usePermissions, useResourceContext
} from "react-admin";
import {DefaultPagination} from "../DefaultPagination";
import Scrollbar from "../theme/components/Scrollbar";
import {Card, Container, Grid, Stack, Typography} from "@mui/material";
import Page from "../theme/components/Page";
import {MyDatagrid} from "../theme/components/MyDatagrid";
import {ResourceConfigItem, UpdateFieldsOptions} from "./ResourceConfig";
import {EmptyContent} from "../theme/components/EmptyContent";
import {useForm, useFormContext, useFormState} from "react-hook-form";
import {useRecordContext} from "ra-core";
import {CopyButton} from "../components/CopyButton";

const postFilters = [
    <TextInput label="Zoeken..." source="q" alwaysOn/>
];

export const ResourceList: React.FC<{ config: ResourceConfigItem }> = ({config, ...props}) => {
    const canCreate = !!config.createFields || !!config.CreateComponent

    return (
        <Page title={config.pageTitle}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {config.title}
                    </Typography>
                    {canCreate && <CreateButton variant="contained" label="Create" size="medium"/>}
                </Stack>

                <Card>
                    <List component="div" sort={config.defaultSort}
                          pagination={<DefaultPagination/>} /*filters={postFilters}*/
                          hasCreate={false} exporter={false} sx={{
                        "& .MuiToolbar-root": {
                            marginLeft: 3,
                            marginRight: 3,
                            marginTop: 1
                        }
                    }} empty={<ResourceEmpty {...props} config={config}/>}>
                        <Scrollbar>
                            <MyDatagrid rowClick="edit" bulkActionButtons={false} sort={{field: "urn", order: "ASC"}}>
                                {config.listFields}
                            </MyDatagrid>
                        </Scrollbar>
                    </List>
                </Card>
            </Container>
        </Page>
    )
};

const ResourceEditToolbar: React.FC<{ canEditOwn: boolean; isAdmin: boolean; transform?: Function }> = (props) => {
    const {canEditOwn, isAdmin, transform} = props;

    const record = useRecordContext();
    const isSystem = record?.owner?.ownerType === "SYSTEM";

    const disabled = isSystem ? !isAdmin : !canEditOwn;

    return (
        <Toolbar>
            <Grid container justifyContent="space-between" alignItems="flex-end">
                <Grid item>
                    <SaveButton label="Save" disabled={disabled}/>

                    {isSystem && !isAdmin && (
                        <>
                            <CopyButton sx={{ml: 2}} transform={transform}/>

                            <Typography component="span" variant="body2" sx={{ml: 1}}>
                                You cannot edit this system resource. Create a copy to customise.
                            </Typography>
                        </>
                    )}

                    {(isSystem && !canEditOwn) && <Typography component="span" variant="body2" sx={{ml: 2}}>
                        You do not have permission to edit resources.
                    </Typography>}
                </Grid>
                <Grid item>
                    <DeleteWithConfirmButton disabled={disabled}/>
                </Grid>
            </Grid>
        </Toolbar>
    );
};

export const ResourceEdit: React.FC<{config: ResourceConfigItem}> = ({config}) => {
    const {EditFieldComponent} = config;
    const {permissions = []} = usePermissions();
    const isAdmin = permissions.includes("ADMIN");
    const canEdit = permissions.includes("TEXT_EDITOR") || isAdmin;

    const updateFieldsOptions: UpdateFieldsOptions = {
        disabled: !canEdit
    };

    const renderFields = () => {
        if (EditFieldComponent) {
            return <EditFieldComponent {...updateFieldsOptions}/>;
        } else {
            return config.editFields?.(updateFieldsOptions);
        }
    };

    return (
        <Page title={config.pageTitle}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit {config.title}
                    </Typography>
                </Stack>

                <Edit mutationMode="pessimistic" {...config.editProps}>
                    <SimpleForm toolbar={
                        <ResourceEditToolbar canEditOwn={canEdit} isAdmin={isAdmin} transform={config.editProps?.transform}/>
                    }>
                        {renderFields()}
                    </SimpleForm>
                </Edit>
            </Container>
        </Page>
    );
};

export const ResourceCreate: React.FC<{ config: ResourceConfigItem }> = ({config}) => (
    <Page title="Aspecten">
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Create {config.title}
                </Typography>
                {/*<Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill"/>}>*/}
                {/*    New User*/}
                {/*</Button>*/}
            </Stack>

            <Create {...config.createProps}>
                <SimpleForm toolbar={
                    <Toolbar>
                        <SaveButton label="Save"/>
                    </Toolbar>
                }>
                    {config.createFields}
                </SimpleForm>
            </Create>
        </Container>
    </Page>
);

export const ResourceEmpty: React.FC<{ config: ResourceConfigItem }> = ({config}) => {
    return (
        <Container>
            {/*<EmptyContent title="Geen resultaten" description="Maak een item aan met de 'Aanmaken' knop."/>*/}
            <EmptyContent title="No results" description="Create a new item using the 'Create' button."/>
        </Container>
    );
};
