import {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Avatar, Badge, Box, Drawer, Link, Typography} from '@mui/material';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import {NavSectionWithHeadings} from '../../components/NavSection';
//
import navConfig from './NavConfig';
import {useGetIdentity} from "react-admin";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
    },
}));

const AccountStyle = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: (theme.palette.grey as any)[500_12]
}));

// ----------------------------------------------------------------------

export interface DashboardSidebarProps {
    isOpenSidebar?: boolean;
    onCloseSidebar: () => any;
}

const DashboardSidebar: React.FC<DashboardSidebarProps> = ({isOpenSidebar, onCloseSidebar}) => {
    const {pathname} = useLocation();

    const {identity} = useGetIdentity();

    const isDesktop = useResponsive('up', 'lg');

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {height: 1, display: 'flex', flexDirection: 'column'},
            }}
        >
            <Box sx={{px: 2.5, py: 3, display: 'inline-flex'}}>
                <Logo/> <Badge badgeContent="beta" color="warning" anchorOrigin={{horizontal: "right", vertical: "bottom"}}/>
            </Box>

            {/*<Box sx={{mb: 5, mx: 2.5}}>*/}
            {/*    <Link underline="none" component={RouterLink} to="#">*/}
            {/*        <AccountStyle>*/}
            {/*            <Avatar src={account.photoURL} alt="photoURL"/>*/}
            {/*            <Box sx={{ml: 2}}>*/}
            {/*                <Typography variant="subtitle2" sx={{color: 'text.primary'}}>*/}
            {/*                    {identity?.fullName}*/}
            {/*                </Typography>*/}
            {/*                <Typography variant="body2" sx={{color: 'text.secondary'}}>*/}
            {/*                    {identity?.role}*/}
            {/*                </Typography>*/}
            {/*            </Box>*/}
            {/*        </AccountStyle>*/}
            {/*    </Link>*/}
            {/*</Box>*/}

            <NavSectionWithHeadings navConfig={navConfig}/>

            <Box sx={{flexGrow: 1}}/>

            {/*<Box sx={{ px: 2.5, pb: 3, mt: 10 }}>*/}
            {/*  <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>*/}
            {/*    <Box*/}
            {/*      component="img"*/}
            {/*      src="/static/illustrations/illustration_avatar.png"*/}
            {/*      sx={{ width: 100, position: 'absolute', top: -50 }}*/}
            {/*    />*/}

            {/*    <Box sx={{ textAlign: 'center' }}>*/}
            {/*      <Typography gutterBottom variant="h6">*/}
            {/*        Get more?*/}
            {/*      </Typography>*/}
            {/*      <Typography variant="body2" sx={{ color: 'text.secondary' }}>*/}
            {/*        From only $69*/}
            {/*      </Typography>*/}
            {/*    </Box>*/}

            {/*    <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">*/}
            {/*      Upgrade to Pro*/}
            {/*    </Button>*/}
            {/*  </Stack>*/}
            {/*</Box>*/}
        </Scrollbar>
    );

    return (
        <RootStyle>
            {!isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: {width: DRAWER_WIDTH},
                    }}
                >
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}

export default DashboardSidebar;
