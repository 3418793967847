import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {Box, Button, Card, Container, Link, Stack, Tab, Tabs, Typography} from "@mui/material";
import useResponsive from "../theme/hooks/useResponsive";
import Page from "../theme/components/Page";
import Logo from "../theme/components/Logo";
import {NavLink as RouterLink} from "react-router-dom";
import {RegisterForm} from "./components/RegisterForm";
import Iconify from "../theme/components/Iconify";
import {Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import AccountGeneral from "./account/AccountGeneral";
import AccountBilling from "./account/AccountBilling";
import AccountNotifications from "./account/AccountNotifications";
import AccountSocialLinks from "./account/AccountSocialLinks";
import AccountChangePassword from "./account/AccountChangePassword";
import HeaderBreadcrumbs from "../theme/components/HeaderBreadcrumbs";

const _userPayment = [...Array(2)].map((_, index) => ({
    id: "123",
    cardNumber: ['**** **** **** 1234', '**** **** **** 5678', '**** **** **** 7878'][index],
    cardType: ['master_card', 'visa', 'master_card'][index],
}));

const _userAddressBook = [...Array(4)].map((_, index) => ({
    id: "123",
    name: "Dean",
    phone: "123",
    country: "BE",
    state: 'New Hampshire',
    city: 'East Sambury',
    street: '41256 Kamille Turnpike',
    zipCode: '85807',
}));

const _userInvoices = [...Array(10)].map((_, index) => ({
    id: "123",
    createdAt: new Date(),
    price: 29
}));

export const _userAbout = {
    id:  "123",
    cover: "",
    position: 'UI Designer',
    follower: 1,
    following: 1,
    quote: 'Tart I love sugar plum I love oat cake. Sweet roll caramels I love jujubes. Topping cake wafer..',
    country: "BE",
    email: "dean@wyns.dev",
    company: "company",
    school: "school",
    role: 'Manager',
    facebookLink: `https://www.facebook.com/caitlyn.kerluke`,
    instagramLink: `https://www.instagram.com/caitlyn.kerluke`,
    linkedinLink: `https://www.linkedin.com/in/caitlyn.kerluke`,
    twitterLink: `https://www.twitter.com/caitlyn.kerluke`,
};

export const AccountPage: React.FC = (props) => {
    // const { themeStretch } = useSettings();

    const [currentTab, setCurrentTab] = useState("billing");

    const ACCOUNT_TABS = [
        // {
        //     value: 'general',
        //     icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
        //     component: <AccountGeneral />,
        // },
        {
            value: 'billing',
            label: "Billing",
            icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
            component: <AccountBilling cards={_userPayment} addressBook={_userAddressBook} invoices={_userInvoices} />,
        },
        // {
        //     value: 'notifications',
        //     icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
        //     component: <AccountNotifications />,
        // },
        // {
        //     value: 'social_links',
        //     icon: <Iconify icon={'eva:share-fill'} width={20} height={20} />,
        //     component: <AccountSocialLinks myProfile={_userAbout} />,
        // },
        // {
        //     value: 'change_password',
        //     label: "Security",
        //     icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
        //     component: <AccountChangePassword />,
        // },
    ];


    return (
        <Page title="User: Account Settings">
            <Container maxWidth={'lg'} sx={{pb: 5}}>
                <HeaderBreadcrumbs
                    heading="Account"
                    links={[
                        {name: 'Dashboard', href: "/"},
                        {name: 'Account Settings'}
                    ]} action={undefined} sx={undefined}                />

                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {ACCOUNT_TABS.map((tab) => (
                        <Tab disableRipple key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
                    ))}
                </Tabs>

                <Box sx={{ mb: 5 }} />

                {ACCOUNT_TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })}
            </Container>
        </Page>
    );
};
